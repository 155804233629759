import { FC } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { setUserData } from '../../helpers/utils'
import { AccountForm } from './AccountForm'

export const Login: FC = () => {
  let history = useHistory()

  const handleLogin = (userData: any) => {
    setUserData(userData)
    history.push('/trips')
  }

  return (
    <div>
      <div className="w-full flex flex-col max-w-md m-auto">
        <div className="flex justify-center">
          <a href="https://www.practicallymagic.co/" className="p-4">
            <img src="/assets/logo.png" alt="Practically Magic logo" />
          </a>
        </div>

        <AccountForm
          submit="Login"
          requestPath="session"
          onSuccess={handleLogin}
          footer={
            <>
              <p>
                Don't have an account?{' '}
                <Link
                  className="underline font-semibold text-black-900"
                  to="/sign-up"
                >
                  Register here
                </Link>
              </p>
              <p className="mt-3 text-sm">
                <Link className="underline text-black" to="/forgot-password">
                  Forgot Password?
                </Link>
              </p>
            </>
          }
        />
      </div>
    </div>
  )
}
