import { FC } from 'react'
import { displayTime } from '../../../helpers/utils'
import { Attraction } from '../../../types/items/'
import { PDFItemDetail, PDFItemDetails } from './helpers/PDFItemDetail'

export const PDFAttractionItem: FC<{ item: Attraction; date: Date }> = ({
  item,
  date,
}) => {
  let timeValue = displayTime(item.startTime!)
  if (item.passEnabled) timeValue += ` - ${displayTime(item.endTime!)}`

  return (
    <PDFItemDetails>
      {item.fastPass && (<PDFItemDetail field="Time" value={timeValue} />)}
      <PDFItemDetail field="Location" value={item.location} />
      {item.height && !item.isAnyHeight && (
        <PDFItemDetail field="Height" value={item.height} />
      )}
    </PDFItemDetails>
  )
}
