import { FC, useState } from "react"
import { BasicTextFieldProp } from "./BasicTextField"
import { FormControl, FormLabel } from "@material-ui/core"
import { SketchPicker } from "react-color"

export const ColorField: FC<BasicTextFieldProp> = ({
  errors,
  name,
  label,
  value,
  helperText,
  required,
  register,
}) => {
  const [currentValue, setCurrentValue] = useState(value)
  const [showPicker, setShowPicker] = useState(false)

  return (
    <FormControl className="ml-20 h-48">
      <FormLabel className="mb-3">{label}</FormLabel>
      <div className="p-1 bg-white shadow w-20 h-8 cursor-pointer" onClick={() => setShowPicker(!showPicker)}>
        <div className="w-full h-full" style={{backgroundColor: currentValue}} />
      </div>
      <div style={{position: 'absolute', top: "20px", zIndex: 2, display: showPicker ? 'block' : 'none'}}>
        <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0}} onClick={() => setShowPicker(false)} />
        <SketchPicker
          presetColors={[]}
          disableAlpha={true}
          color={currentValue}
          onChange={(color) => setCurrentValue(color.hex)}
        />
      </div>

      <input value={currentValue} name={name} type="hidden" ref={register({ required })} />
    </FormControl>
  )
}
