import { TextFieldProps } from '@material-ui/core/TextField/TextField'
import { FC, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

interface RichTextFieldInterface {
  errors: any
  name: string
  label: string
  value?: any
  helperText?: string
  required?: boolean
  validationProps?: any
  register: any
}
export type RichTextFieldProp = RichTextFieldInterface & TextFieldProps

export const RichTextField: FC<RichTextFieldProp> = ({
  errors,
  name,
  label,
  value,
  helperText,
  required,
  register,
  validationProps,
  ...other
}) => {
  // Note: We need to explicitly set required to false. This fixes a bug where
  // we could register a field as required and then re-register it as not required.
  // This happens for the fast pass time field.
  const registerProps = required
    ? { required: `${label} is required.`, ...validationProps }
    : { required: false, ...validationProps }

  const [quillValue, setQuillValue] = useState(value || "");

  return (
    <div className="MuiFormControl-root MuiTextField-root w-full">
      <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-shrink MuiInputLabel-outlined" data-shrink="true">
        Notes
      </label>
      <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl w-full">
        <ReactQuill
          className="w-full"
          theme="snow"
          value={quillValue}
          onChange={setQuillValue}
          modules={{toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['link'],
            ['clean']
          ]}}
        />
      </div>
      <input value={quillValue} readOnly name={name} type="hidden" ref={register(registerProps)} />
    </div>
  )
}
