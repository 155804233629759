import { FC } from 'react'
import { displayTime } from '../../../helpers/utils'
import { Ground } from '../../../types/items/'
import { PDFItemDetail, PDFItemDetails } from './helpers/PDFItemDetail'

export const PDFGroundItem: FC<{ item: Ground; date: Date }> = ({
  item,
  date,
}) => {
  return (
    <>
      <PDFItemDetails>
        <PDFItemDetail field="Time" value={displayTime(item.pickUpAt)} />
        <PDFItemDetail field="Confirmation" value={item.confirmation || ''} />
      </PDFItemDetails>
      <PDFItemDetails>
        <PDFItemDetail field="Company" value={item.company} />
      </PDFItemDetails>
      <PDFItemDetails>
        <PDFItemDetail field="From" value={item.pickUpLocation} />
        <PDFItemDetail field="To" value={item.dropOffLocation} />
      </PDFItemDetails>
    </>
  )
}
