import { Snackbar } from '@material-ui/core'
import { FC, ReactNode } from 'react'

export interface SnackBarOptions {
  message: ReactNode
}

interface SnackBarMessageProps extends SnackBarOptions {
  open: boolean
  onClose: () => void
}

export const SnackBarMessage: FC<SnackBarMessageProps> = ({
  open,
  message,
  onClose,
}) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
      message={message}
      // action={action}
    />
  )
}
