import axios from 'axios'
import { useState } from 'react'
import { getApiUrl, useEffectOnce } from '../utils'

type CrudInstance = {
  fullId: number | string
}

type CrudClass<T> = {
  build(props: any): T
  apiPath: string
}

/*
A react hook that manages CRUD operations for resources.
 1. Auto fetches list and exposes loading status.
 2. Provides add, update, destroy methods which will keep list sync'd.

Usage:
 1. const resourceCrud = useCrud(ResourceClass) -- e.g. useCrud(Trip)
 2. ResourceClass must have a "build" factory that takes an object and returns an instance.
 3. ResourceClass must define an "apiPath".
 4. Instances of ResourceClass must define a "fullId".
*/
export function useCrud<T extends CrudInstance>(
  Type: CrudClass<T>,
  loadList: boolean = true
) {
  const [data, setData] = useState<T[]>([])
  const [loading, setLoading] = useState(true)

  useEffectOnce(() => {
    if (loadList) {
      refetchList()
    }
  })

  const refetchList = (data?: any) => {
    setLoading(true)
    return axios.get(getApiUrl(Type.apiPath), { params: data })
      .then((res) => { return setData(res.data.map((d: any) => Type.build(d))) })
      .catch((e) => { alert(e); throw(e) })
      .finally(() => setLoading(false))
  }

  const get = (id?: string, extra?: string) => {
    return axios.get(getApiUrl(Type.apiPath, id, extra))
      .then(res => Type.build(res.data))
      .catch((e) => { alert(e); throw(e) })
  }

  const add = (toAdd: any) => {
    return axios.post(getApiUrl(Type.apiPath), toAdd).then((res) => {
      const obj = Type.build({
        ...res.data,
        type: res.data.type,
      })
      setData([...data, obj])
      return obj
    })
    .catch((e) => { alert(e); throw(e) })
  }

  const update = (newData: any, extra?: string) => {
    const fullId = Type.build(newData).fullId
    return axios
      .patch(getApiUrl(Type.apiPath, fullId, extra), newData)
      .then(({ data: updatedRecord }) => {
        setData(
          data.map((record) => {
            if (record.fullId === fullId) {
              return Type.build(updatedRecord)
            }
            return record
          })
        )
        return updatedRecord
      })
      .catch((e) => { alert(e); throw(e) })
  }

  const duplicate = (toDuplicate: any) => {
    setLoading(true)
    return axios
      .post(getApiUrl(Type.apiPath, toDuplicate.id, 'duplicate'), toDuplicate)
      .then((res) => {
        if (res.data) {
          const obj = Type.build(res.data)
          setData([...data, obj])
          return obj
        }
      })
      .catch((e) => { alert(e); throw(e) })
      .finally(() => setLoading(false))
  }

  const purchase = (toPurchase: any) => {
    setLoading(true)
    return axios.post(getApiUrl(Type.apiPath, toPurchase.id, 'purchase'))
      .catch((e) => { alert(e); throw(e) })
      .finally(() => setLoading(false))
  }

  const destroy = (toDelete: any) => {
    const fullId = Type.build(toDelete).fullId

    return axios.delete(getApiUrl(Type.apiPath, fullId)).then(() => {
      let dataDelete = [...data]
      // Find our removed row by id and type.
      const index = dataDelete.findIndex((d) => d.fullId === fullId)
      dataDelete.splice(index, 1)
      setData(dataDelete)
    })
    .catch((e) => { alert(e); throw(e) })
  }

  return {
    refetchList,
    list: data,
    loading: loading,
    get,
    add,
    update,
    duplicate,
    destroy,
    purchase,
  }
}
