import { FormHelperText } from '@material-ui/core'
import axios from 'axios'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { getApiUrl, setFormErrors, setUserData } from '../../helpers/utils'
import { accountFormInputClasses } from './AccountForm'

interface ResetPasswordProps extends RouteComponentProps {}

export const ResetPassword: FC<ResetPasswordProps> = ({
  history,
  location,
}) => {
  const { register, errors, setError, handleSubmit } = useForm()
  const token = new URLSearchParams(location.search).get('token') || ''

  const onSubmit = async (data: any) => {
    // ensure CSRF cookie is set
    await axios.get('/api/session')
    // actually try to reset the password
    const result = await axios.patch(
      getApiUrl('password_reset'),
      data
    )
    if (result.data.errors) {
      setFormErrors(result.data.errors, setError)
    } else {
      setUserData(result.data)
      history.push('/trips')
    }
  }

  return (
    <div>
      <div className="w-full flex flex-col max-w-md m-auto">
        <div className="flex justify-center pt-12">
          <a href="https://www.practicallymagic.co/" className="p-4">
            <img src="/assets/logo.png" alt="Practically Magic logo" />
          </a>
        </div>

        <div className="flex flex-col justify-center my-auto px-8">
          <p className="text-center text-3xl">Reset Password</p>
          {!token && (
            <div className="bg-black-100 mt-12 p-3">
              Cannot reset password without a reset token. Visit the{' '}
              <Link className="underline text-black" to="/forgot-password">
                Forgot Password?
              </Link>{' '}
              page to send a password reset email.
            </div>
          )}

          {token && (
            <form
              className="flex flex-col pt-3"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-col pt-4">
                <label htmlFor="password" className="text-lg">
                  New Password
                </label>
                <input
                  ref={register({ required: true })}
                  type="password"
                  name="password"
                  className={accountFormInputClasses}
                />
                {errors.password && (
                  <FormHelperText error>
                    {errors.password.message}
                  </FormHelperText>
                )}
              </div>

              <div className="flex flex-col pt-4">
                <label htmlFor="passwordConfirmation" className="text-lg">
                  Confirm New Password
                </label>
                <input
                  ref={register({ required: true })}
                  type="password"
                  name="passwordConfirmation"
                  className={accountFormInputClasses}
                />
                {errors.passwordConfirmation && (
                  <FormHelperText error>
                    {errors.passwordConfirmation.message}
                  </FormHelperText>
                )}
              </div>

              <input
                type="submit"
                value="Reset Password"
                className="cursor-pointer bg-black-900 text-white font-bold text-lg hover:bg-gray-700 p-2 mt-8"
              />

              <input
                ref={register()}
                type="hidden"
                name="resetPasswordToken"
                value={token}
              />
              {errors.resetPasswordToken && (
                <FormHelperText error>
                  {errors.resetPasswordToken.message} Visit{' '}
                  <Link className="underline text-black" to="/forgot-password">
                    Forgot Password?
                  </Link>{' '}
                  to resend reset password email.
                </FormHelperText>
              )}
            </form>
          )}
          <div className="text-center py-12">
            <p className="text-sm">
              Go to{' '}
              <Link className="underline text-black-900" to="/login">
                Login Page
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
