import { FormFieldTypes } from '../../components/forms/FormBuilder'
import { Trip } from '../trip'
import { ItemType, ItineraryItem, ItineraryItemInterface } from './base'

interface TicketInterface extends ItineraryItemInterface {
  startAt?: Date
  name: string
}
export interface Ticket extends TicketInterface {}
export class Ticket extends ItineraryItem {
  type = ItemType.TICKET

  protected get dates() {
    return this.startAt ? [this.startAt] : []
  }

  static itineraryFormFields(trip: Trip) {
    return [
      { name: 'name', type: FormFieldTypes.STRING, required: true },
      {
        name: 'startAt',
        label: '',
        type: FormFieldTypes.DATE_TIME,
        required: false,
      },
      { name: 'confirmation', type: FormFieldTypes.STRING, required: false },
      { name: 'notes', type: FormFieldTypes.TEXTAREA, required: false },
    ]
  }

  isTimeBased() {
    return !!this.startAt
  }

  get startName() {
    return 'Date'
  }

  get title() {
    return this.name
  }

  isSavable() {
    return false
  }
}
