import { FormHelperText } from '@material-ui/core'
import axios from 'axios'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { useSnackBar } from '../../../components/ui/SnackBarService'
import { getApiUrl, setFormErrors, setUserData } from '../../../helpers/utils'
import { Agent } from '../../../types/agent'
import { accountFormInputClasses } from '../AccountForm'

interface AcceptInviteNewUserProps {
  agency: string
  token: string
  email: string
}

export const AcceptInviteNewUser: FC<AcceptInviteNewUserProps> = ({
  agency,
  token,
  email,
}) => {
  const { register, errors, setError, handleSubmit } = useForm()
  let history = useHistory()
  const snackBar = useSnackBar()

  const onSubmit = async (data: any) => {
    const result = await axios.post(
      getApiUrl(Agent.apiPath, null, 'accept_invite'),
      data
    )

    if (result.data.errors) {
      setFormErrors(result.data.errors, setError)
    } else {
      setUserData(result.data)
      history.push('/trips')
      snackBar({ message: `Successfully joined ${agency}.` })
    }
  }

  return (
    <form className="flex flex-col pt-3" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col pt-4">
        <label className="text-lg">Email</label>
        <input className={accountFormInputClasses} value={email} disabled />
      </div>
      <div className="flex flex-col pt-4">
        <label htmlFor="password" className="text-lg">
          Password
        </label>
        <input
          ref={register({ required: true })}
          type="password"
          name="password"
          className={accountFormInputClasses}
        />
        {errors.password && (
          <FormHelperText error>{errors.password.message}</FormHelperText>
        )}
      </div>

      <div className="flex flex-col pt-4">
        <label htmlFor="passwordConfirmation" className="text-lg">
          Confirm Password
        </label>
        <input
          ref={register({ required: true })}
          type="password"
          name="passwordConfirmation"
          className={accountFormInputClasses}
        />
        {errors.passwordConfirmation && (
          <FormHelperText error>
            {errors.passwordConfirmation.message}
          </FormHelperText>
        )}
      </div>

      <input ref={register()} type="hidden" name="token" value={token} />

      <input
        type="submit"
        value="Accept Invite & Create Account"
        className="cursor-pointer bg-black-900 text-white font-bold text-lg hover:bg-gray-700 p-2 mt-8"
      />
    </form>
  )
}
