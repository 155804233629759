import { FormHelperText } from '@material-ui/core'
import axios from 'axios'
import cx from 'classnames'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { emailRegex, setUserData } from '../../helpers/utils'

export const accountFormInputClasses =
  'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:ring'

export const accountFormCheckboxInputClasses = 'w-5 h-5 rounded mr-2'
interface AccountFormProp {
  className?: string
  heading?: string
  submit: string
  requestPath: string
  onSuccess: (data: any) => void
  footer: ReactNode
}

export const AccountForm: FC<AccountFormProp> = ({
  className,
  heading,
  submit,
  requestPath,
  onSuccess,
  footer,
}) => {
  let history = useHistory()
  const { register, errors, setError, handleSubmit } = useForm()
  const [user, setUser] = useState(undefined)

  // We use an effect here so that we invoke this every time this component is shown.
  // This fixes issue when logging in, out, in.
  useEffect(() => {
    axios.get('/api/session').then((res) => {
      setUser(res.data)
    })
  }, [])

  // Determine if user is already logged in. This serves two purposes:
  //  1. Allows us to redirect to /trips if they are logged in.
  //  2. Makes sure we have done at least a single request so that CSRF is set.
  if (user === undefined) {
    // Return null while we wait for the response.
    return null
  } else if (user) {
    setUserData(user)
    setTimeout(() => history.push('/trips'))
    return null
  }

  const onSubmit = async (data: any) => {
    try {
      const result = await axios.post(`/api/${requestPath}/`, data)
      onSuccess(result.data)
    } catch (error) {
      if (!error.response.data.errors) { throw(error) }
      Object.entries(error.response.data.errors).forEach(([key, value]) => {
        setError(key, { type: 'manual', message: value as string })
      })
    }
  }

  return (
    <div
      className={cx(
        'flex flex-col justify-center my-auto px-8 space-y-3',
        className
      )}
    >
      {heading && <p className="text-center text-3xl">{heading}</p>}
      <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col pt-4">
          <label htmlFor="email" className="text-lg">
            Email
          </label>
          <input
            ref={register({
              required: 'Email is Required.',
              pattern: {
                value: emailRegex,
                message: 'Invalid Email Address.',
              },
            })}
            type="email"
            name="email"
            placeholder="your@email.com"
            className={cx(accountFormInputClasses, {
              'border-red-500': errors.email,
            })}
          />

          {errors.email && (
            <FormHelperText error>{errors.email.message}</FormHelperText>
          )}
        </div>

        <div className="flex flex-col pt-4">
          <label htmlFor="password" className="text-lg">
            Password
          </label>
          <input
            ref={register({ required: 'Password is required.' })}
            type="password"
            name="password"
            placeholder="Password"
            className={cx(accountFormInputClasses, {
              'border-red-500': errors.password,
            })}
          />

          {errors.password && (
            <FormHelperText error>{errors.password.message}</FormHelperText>
          )}
        </div>

        {submit === 'Sign Up' && (
          <div className="flex flex-col pt-4">
            <div className="flex mr-4 pt-4">
              <input
                ref={register({ required: 'Required.' })}
                type="checkbox"
                id="checkbox-id"
                name="checkbox"
                className={cx(accountFormCheckboxInputClasses, {
                  'border-red-500': errors.checkbox,
                })}
              />
              <label htmlFor="checkbox" className="text-base">
                <a
                  href="https://www.practicallymagic.co/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="text-current"
                >
                  <span className="underline">
                    I agree to the terms and conditions.
                  </span>
                </a>
              </label>
            </div>
            {errors.checkbox && (
              <FormHelperText error>{errors.checkbox.message}</FormHelperText>
            )}
          </div>
        )}

        <input
          type="submit"
          value={submit}
          className="cursor-pointer bg-black-900 text-white font-bold text-lg hover:bg-gray-700 p-2 mt-8"
        />
      </form>

      <div className="text-center pt-12 pb-12">{footer}</div>
    </div>
  )
}
