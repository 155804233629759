import cx from 'classnames'
import { FC } from 'react'

/*

PDFItemDetails and PDFItemDetail can be used together to setup the layout and
styles of our PDF item attributes. Null checking is not needed as these
components take care of that for us.

Usage:
<PDFItemDetails>
  <PDFItemDetail field="foo" value="bar">
  <PDFItemDetail field="hello" value="world">
  <PDFItemDetail field="Confirmation" value={null}>
</PDFItemDetails>

Will return:
foo: bar          hello: world

 */

export const PDFItemDetails: FC<{
  className?: string
}> = ({ children, className }) => {
  return <div className={cx('flex flex-wrap gap-x-4', className)}>
    {children}
  </div>
}

// Renders a field: value for a PDF item. Returns null when value is null || undefined.
export const PDFItemDetail: FC<{
  className?: string
  field: string
  value?: string | null
}> = ({ className, field, value }) => {
  if (!value) {
    return null
  }

  const boldValue = !['location', 'height'].includes(field.toLowerCase())
  return (
    <div className={cx('flex flex-auto space-x-2', className)}>
      <span className="capitalize font-light">{field}: </span>
      <span className={boldValue ? "font-semibold" : ""}>{value}</span>
    </div>
  )
}
