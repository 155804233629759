import { FormFieldTypes } from '../../components/forms/FormBuilder'
import { itemTemplateFields } from '../content/template'
import { Trip } from '../trip'
import { ItemType, ItineraryItem, ItineraryItemInterface } from './base'

interface GroundInterface extends ItineraryItemInterface {
  name: string
  company: string
  pickUpAt: Date
  pickUpLocation: string
  dropOffLocation: string
}

export interface Ground extends GroundInterface {}
export class Ground extends ItineraryItem {
  type = ItemType.GROUND

  protected get dates() {
    return this.pickUpAt ? [this.pickUpAt] : []
  }

  static itineraryFormFields(trip: Trip) {
    return this.itineraryFormFieldsHandler(false)
  }

  static itineraryOnlyFormFields(trip: Trip) {
    return [...this.itineraryFormFieldsHandler(true), ...itemTemplateFields()]
  }

  static itineraryFormFieldsHandler(itineraryOnly: boolean) {
    return [
      {
        name: 'pickUpAt',
        type: FormFieldTypes.DATE_TIME,
        required: false,
      },
      {
        name: 'locations',
        type: FormFieldTypes.ROW,

        // Name Prefix is needed to include pickUpLocation and dropOffLocation in the items object.
        namePrefix: itineraryOnly ? undefined : 'item',
        fields: [
          {
            name: 'pickUpLocation',
            type: FormFieldTypes.STRING,
            required: false,
          },
          {
            name: 'dropOffLocation',
            type: FormFieldTypes.STRING,
            required: false,
          },
        ],
      },
      { name: 'confirmation', type: FormFieldTypes.STRING, required: false },
    ]
  }

  get startName() {
    return 'Pick Up'
  }

  get title() {
    return this.name
  }

  pdfTitle(date: Date): string {
    return `${this.title}`
  }

  isTimeBased() {
    return !!this.pickUpAt
  }
}
