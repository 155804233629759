import { Button } from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
import cx from 'classnames'
import { FC, FormEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { setTime } from '../../helpers/utils'
import { FormBuilderField } from './helpers/FormBuilderField'

export enum FormFieldTypes {
  HIDDEN,
  GROUP,
  ROW,
  ARRAY,
  DATE,
  DATE_TIME,
  IMAGE,
  LIST,
  STRING,
  EMAIL,
  PASSWORD,
  SWITCH,
  CHECKBOX,
  TEXTAREA,
  TIME,
  COLOR,
  SELECT,
}

export interface FormFieldDescription {
  name: string
  label?: string
  type: FormFieldTypes
  requiredToggle?: string
  disabledToggle?: string
  helperText?: string
  required?: boolean
  fields?: FormFieldDescription[]
  namePrefix?: string
  fieldProps?: any
  options?: { value: string; label: string; className?: string; }[]
  exclusiveWith?: string
}

export const FormBuilder: FC<{
  className?: string
  item?: any
  formDesc: FormFieldDescription[]
  onSubmit: (data: any, setError?: any, reset?: any) => Promise<any>
  onCancel?: () => void
  fieldProps?: any
  needsReconfirm?: boolean
  tight?: boolean
  saveText?: string
  disabled?: boolean
}> = ({
  className,
  item,
  formDesc,
  onSubmit,
  onCancel,
  fieldProps,
  needsReconfirm,
  tight,
  saveText,
  disabled,
}) => {
  const {
    formState,
    register,
    control,
    getValues,
    setError,
    reset,
    clearErrors,
    unregister,
    handleSubmit,
    errors,
  } = useForm()

  const [saving, setSaving] = useState(false)
  const [, updateState] = useState<any>()

  const handleToggleHelper = (
    fields: FormFieldDescription[],
    name: string,
    namePrefix?: string
  ) => {
    for (const field of fields) {
      if (field.fields) {
        handleToggleHelper(field.fields, name, namePrefix)
      } else {
        if ((field.disabledToggle || field.requiredToggle) === name) {
          const prefixedName = namePrefix
            ? `${namePrefix}.${field.name}`
            : field.name
          // Removes any errors and allows it to be re-registered with
          // new validations.
          unregister(prefixedName)
          unregister(`${prefixedName}.date`)
          unregister(`${prefixedName}.time`)
        }
      }
    }
  }

  const handleToggle = (name: string, namePrefix?: string) => {
    updateState({}) // force update

    handleToggleHelper(formDesc, name, namePrefix)
  }

  const onCancelWrapper = (event: FormEvent) => {
    // Undo "_destroy" changes done via FormBuilderField:handleRemoveArrayEntry.
    if (item) {
      for (const [, entry] of Object.entries(item)) {
        if (Array.isArray(entry)) {
          for (const value of entry) {
            if (value.hasOwnProperty('_destroy')) {
              value._destroy = false
            }
          }
        }
      }
    }

    if (onCancel) onCancel()
  }

  const onSubmitWrapper = async (data: any) => {
    setSaving(true)

    let hasErrors = false
    const fixupFields = (
      fieldDescriptions: FormFieldDescription[],
      data: any,
      namePrefix?: string
    ) => {
      for (const fieldDesc of fieldDescriptions) {
        // Recursively loop through fields.
        if (fieldDesc.fields) {
          switch (fieldDesc.type) {
            case FormFieldTypes.ARRAY:
              for (const childData of data[fieldDesc.name]) {
                // Sometimes this can be empty when removing an array entry.
                if (childData) {
                  fixupFields(fieldDesc.fields, childData, fieldDesc.namePrefix)
                }
              }

              // Re-add any entries that are destroyed.
              if (item && item[fieldDesc.name]) {
                for (const itemData of item[fieldDesc.name]) {
                  if (itemData?._destroy) {
                    data[fieldDesc.name].push({
                      id: itemData.id,
                      _destroy: true,
                    })
                  }
                }
              }
              break
            case FormFieldTypes.GROUP:
              const scopedData = fieldDesc.namePrefix
                ? data[fieldDesc.namePrefix]
                : data
              fixupFields(fieldDesc.fields, scopedData, fieldDesc.namePrefix)
              break
            case FormFieldTypes.ROW:
              fixupFields(fieldDesc.fields, data, namePrefix)
              break
          }
        }

        // Process any disabledToggles by removing the disabled toggle value and
        // setting the controlled value to null if the toggle is false.
        if (fieldDesc.disabledToggle) {
          if (!data[fieldDesc.disabledToggle]) {
            data[fieldDesc.name] = null
          }
          delete data[fieldDesc.disabledToggle]
        }

        switch (fieldDesc.type) {
          case FormFieldTypes.ARRAY:
            // Remove empties from array.
            data[fieldDesc.name] = data[fieldDesc.name].filter((v: any) => !!v)
            break
          case FormFieldTypes.DATE_TIME:
            // Recombine datetime's into a single date object.
            if (
              data[fieldDesc.name] &&
              data[fieldDesc.name].date &&
              data[fieldDesc.name].time
            ) {
              data[fieldDesc.name] = setTime(
                data[fieldDesc.name].date,
                data[fieldDesc.name].time
              )
            } else {
              const prefixedName = namePrefix
                ? `${namePrefix}.${fieldDesc.name}`
                : fieldDesc.name

              // Show error if date is set (but no time).
              if (data[fieldDesc.name]?.date) {
                hasErrors = true
                setError(`${prefixedName}.time`, {
                  type: 'manual',
                  message: 'Add a time.',
                  shouldFocus: true,
                })
              }

              // Show error if time is set (but no date).
              if (data[fieldDesc.name]?.time) {
                hasErrors = true
                setError(`${prefixedName}.date`, {
                  type: 'manual',
                  message: 'Add a date.',
                  shouldFocus: true,
                })
              }
              data[fieldDesc.name] = null
            }
            break
        }
      }
    }
    fixupFields(formDesc, data)

    if (!hasErrors) {
      await onSubmit(data, setError, reset)
    }

    setSaving(false)
  }

  return (
    <form
      onSubmit={
        (data) => {
          handleSubmit(onSubmitWrapper)(data)}}
      autoComplete="off"
      className={cx('space-y-10', className)}
      noValidate
    >
      <div className={tight ? 'space-y-6' : 'space-y-10'}>
        {formDesc.map((field) => {
          return (
            <FormBuilderField
              key={field.name}
              handleToggle={handleToggle}
              control={control}
              formState={formState}
              setError={setError}
              clearErrors={clearErrors}
              errors={errors}
              register={register}
              getValues={getValues}
              field={field}
              item={item || {}}
              fieldProps={fieldProps}
              needsReconfirm={needsReconfirm}
              disabled={disabled}
            />
          )
        })}
      </div>

      <div className="space-x-2 text-right">
        {onCancel && (
          <Button
            variant="outlined"
            type="button"
            onClick={onCancelWrapper}
            disabled={disabled || saving}
          >
            Cancel
          </Button>
        )}
        <LoadingButton
          disabled={disabled}
          variant="contained"
          color="primary"
          type="submit"
          pending={saving}
        >
          {saveText || 'Save'}
        </LoadingButton>
      </div>
    </form>
  )
}
