import { FormHelperText } from '@material-ui/core'
import axios from 'axios'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { emailRegex, getApiUrl, setFormErrors } from '../../helpers/utils'
import { accountFormInputClasses } from './AccountForm'

export const ForgotPassword: FC = () => {
  const { register, errors, setError, handleSubmit } = useForm()
  const [submitted, setSubmitted] = useState(false)

  const onSubmit = async (data: any) => {
    const result = await axios.post(
      getApiUrl('password_reset'),
      data
    )

    if (result.data.errors) {
      setFormErrors(result.data.errors, setError)
    } else {
      setSubmitted(true)
    }
  }

  return (
    <div>
      <div className="w-full flex flex-col max-w-md m-auto">
        <div className="flex justify-center">
          <a href="https://www.practicallymagic.co/" className="p-4">
            <img src="/assets/logo.png" alt="Practically Magic logo" />
          </a>
        </div>

        {!submitted && (
          <div className="flex flex-col justify-center my-auto px-8">
            <p className="text-center text-3xl">Reset Password</p>
            <p className="text-center">
              Enter your email address, and we will send you a link to reset
              your password.
            </p>
            <form
              className="flex flex-col pt-3"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-col pt-4">
                <label htmlFor="email" className="text-lg">
                  Email
                </label>
                <input
                  ref={register({
                    required: 'Email is Required.',
                    pattern: {
                      value: emailRegex,
                      message: 'Invalid Email Address.',
                    },
                  })}
                  type="email"
                  name="email"
                  placeholder="your@email.com"
                  className={accountFormInputClasses}
                />

                {errors.email && (
                  <FormHelperText error>{errors.email.message}</FormHelperText>
                )}
              </div>
              <input
                type="submit"
                value="Send Reset"
                className="cursor-pointer bg-black-900 text-white font-bold text-lg hover:bg-gray-700 p-2 mt-8"
              />
            </form>
          </div>
        )}

        {submitted && (
          <div className="bg-black-100 mt-12 p-3">
            An email will be sent to you shortly with instructions on how to
            reset your password.
          </div>
        )}
        <div>
          <div className="text-center py-12">
            <p className="text-sm">
              Go back to{' '}
              <Link className="underline text-black-900" to="/login">
                Login Page
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
