import { add } from 'date-fns'
import { FormFieldTypes } from '../../components/forms/FormBuilder'
import { itemTemplateFields } from '../content/template'
import { Trip } from '../trip'
import { ItemType, ItineraryItem, ItineraryItemInterface } from './base'

interface AttractionInterface extends ItineraryItemInterface {
  name: string
  location: string
  fastPassEnabled: boolean
  dasPassEnabled: boolean
  fastPass?: Date
  height?: string
  tags: string[]
}

export interface Attraction extends AttractionInterface {}
export class Attraction extends ItineraryItem {
  type = ItemType.ATTRACTION

  protected get dates() {
    return this.fastPass ? [this.fastPass] : []
  }

  static itineraryOnlyFormFields(trip: Trip) {
    return [
      {
        name: 'passes',
        type: FormFieldTypes.ROW,
        fields: [
          {
            name: 'fastPassEnabled',
            label: 'Lightning Lane',
            type: FormFieldTypes.SWITCH,
            exclusiveWith: 'dasPassEnabled'
          },
          {
            name: 'dasPassEnabled',
            label: 'Disability Assistance Service (DAS)',
            type: FormFieldTypes.SWITCH,
            exclusiveWith: 'fastPassEnabled'
          },
        ],
      },
      {
        name: 'fastPass',
        label: '',
        type: FormFieldTypes.DATE_TIME,
        required: false
      },
      ...itemTemplateFields(),
    ]
  }

  static itineraryWithContentFormFields(trip: Trip) {
    return [
      {
        name: 'passes',
        type: FormFieldTypes.ROW,
        fields: [
          {
            name: 'fastPassEnabled',
            label: 'Lightning Lane',
            type: FormFieldTypes.SWITCH,
            exclusiveWith: 'dasPassEnabled'
          },
          {
            name: 'dasPassEnabled',
            label: 'Disability Assistance Service (DAS)',
            type: FormFieldTypes.SWITCH,
            exclusiveWith: 'fastPassEnabled'
          },
        ],
      },
      {
        name: 'fastPass',
        label: '',
        type: FormFieldTypes.DATE_TIME,
        required: false
      },
    ]
  }

  get isAnyHeight() {
    return this.height === 'Any Height'
  }

  get startTime() {
    return this.fastPass
  }

  get endTime() {
    return this.fastPass ? add(this.fastPass, { hours: 1 }) : undefined
  }

  isTimeBased() {
    return !!this.fastPass
  }

  get startName() {
    return this.flag
  }

  get title() {
    return this.name
  }

  get flag() {
    if (this.fastPassEnabled) {
      return 'Lightning Lane'
    }
    if (this.dasPassEnabled) {
      return 'DAS'
    }
    return '';
  }

  get passEnabled() {
    return this.fastPassEnabled || this.dasPassEnabled
  }

  pdfTitle(date: Date): string {
    return this.passEnabled ? `${this.title} ${this.flag}` : this.title
  }

  pdfItemTitle(date: Date): string {
    return this.passEnabled ? `${this.flag}: ${this.title}` : this.title
  }
}
