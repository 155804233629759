export enum ContentType {
  ACTIVITY = 'activity',
  ATTRACTION = 'attraction',
  RESTAURANT = 'restaurant',
  LODGING = 'lodging',
  GROUND = 'ground',
  CRUISE = 'cruise',
  NOTES = 'notes',
  FULLDAY = 'full_day'
}

export const ContentTypes = Object.values(ContentType) as ContentType[]
