type AgentInterface = {
  id: number
  email: string
  invited: boolean
  agencyPays: boolean
}

export class Agent {
  id: number
  email: string
  invited: boolean
  agencyPays: boolean

  constructor(agent: AgentInterface) {
    this.id = agent.id
    this.email = agent.email
    this.invited = agent.invited
    this.agencyPays = agent.agencyPays
  }

  get type() {
    return this.invited ? 'invite' : 'agent'
  }

  static build(agent: AgentInterface) {
    return new Agent(agent)
  }

  static apiPath = 'agents'

  get fullId() {
    return `${this.type}/${this.id}`
  }
}
