import { CircularProgress, Paper } from '@material-ui/core'
import cx from 'classnames'
import { FC, ReactNode } from 'react'
import { ImpersonatingBar } from './ImpersonatingBar'
import { NavBar } from './NavBar'

interface PageProps {
  className?: string
  paper?: boolean
  header?: ReactNode
  loading?: boolean
}

export const Page: FC<PageProps> = ({
  children,
  className,
  paper,
  header,
  loading,
}) => {
  const body = loading ? (
    <div className="flex justify-center">
      <CircularProgress />
    </div>
  ) : (
    children
  )

  return (
    <>
      <link rel="manifest" href="/site.webmanifest" />
      <ImpersonatingBar />
      <NavBar />

      {paper ? (
        <div className="flex-grow bg-p_green bg-opacity-15 py-12 under-nav-bar min-w-[1024px]">
          {header}
          <Paper className={cx('p-5 pb-8 mx-auto w-4/5', className)}>
            {body}
          </Paper>
        </div>
      ) : (
        <div className={cx('flex-grow flex under-nav-bar min-w-[1024px]', className)}>{body}</div>
      )}
    </>
  )
}
