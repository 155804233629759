import { FormFieldDescription, FormFieldTypes } from '../../components/forms/FormBuilder'
import { ContentItem } from '../content_item'
import { ItemType, UnsavedItineraryItemInterface } from '../items/base'
import { ContentItemInterface } from './base'

export interface TemplatableContentItem extends ContentItemInterface {
  template?: {
    notes: string
    notes2?: string
  }
}

/**
 * Template form fields that should show up
 * in the context of Content CRUD
 */
export function contentTemplateFields(
  multiNote = false,
  cruise = false
): FormFieldDescription[] {
  if (multiNote || cruise) {
    return [
      {
        name: 'template.notes',
        label: cruise ? 'Notes for Departure' : 'Notes for Check In',
        type: FormFieldTypes.TEXTAREA,
        required: false,
        helperText:
          'Will be used as the initial check in notes when adding using this in the future',
      },
      {
        name: 'template.notes2',
        label: cruise ? 'Notes for arrival' : 'Notes for Check Out',
        type: FormFieldTypes.TEXTAREA,
        required: false,
        helperText:
          'Will be used as the initial check out notes when adding using this in the future',
      },
    ]
  }

  return [
    {
      name: 'template.notes',
      type: FormFieldTypes.TEXTAREA,
      required: false,
      helperText:
        'Will be used as the initial notes when adding this content item in the future',
    },
  ]
}

/**
 * Template form fields that should show up
 * in the context of Item CRUD
 */
export function itemTemplateFields(
  multiNote = false,
  cruise?: boolean
): FormFieldDescription[] {
  if (multiNote) {
    return [
      {
        name: 'notes',
        label: 'Notes for Check In',
        type: FormFieldTypes.TEXTAREA,
        required: false,
      },
      {
        name: 'notes2',
        label: 'Notes for Check Out',
        type: FormFieldTypes.TEXTAREA,
        required: false,
      },
    ]
  }
  //**** We dont want cruise to have notes field props because cruise already have a notes prop  */
  if (cruise) {
    return []
  }
  return [
    {
      name: 'notes',
      type: FormFieldTypes.TEXTAREA,
      required: false,
    },
  ]
}


function isTemplatable(item: ContentItem): boolean {
  return (item as TemplatableContentItem).template !== undefined
}

export function getTemplateNotes(item: ContentItem): string | false {
  if (isTemplatable(item)) {
    return (item as TemplatableContentItem).template?.notes || false
  }
  return false
}

export function buildItemFromContent(
  type: ItemType,
  content?: TemplatableContentItem
): UnsavedItineraryItemInterface {
  return {
    type,
    notes: content?.template?.notes,
    notes2: content?.template?.notes2,
  }
}
