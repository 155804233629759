import { FormFieldTypes } from '../../components/forms/FormBuilder'
import { Trip } from '../trip'
import { ItemType, ItineraryItem, ItineraryItemInterface } from './base'

interface FullDayInterface extends ItineraryItemInterface {
  name: string
}
export interface FullDay extends FullDayInterface {}
export class FullDay extends ItineraryItem {
  type = ItemType.FULL_DAY

  protected get dates() {
    return []
  }

  static itineraryFormFields(trip: Trip) {
    return [
      {
        name: 'startAt',
        type: FormFieldTypes.DATE,
        required: false,
      },
    ]
  }

  isTimeBased() {
    return false
  }

  get startName() {
    return 'Items'
  }

  get title() {
    return this.name
  }

  isSavable() {
    return false
  }
}
