import { Divider, IconButton, Menu, MenuItem } from '@material-ui/core'
import { AccountCircle } from '@material-ui/icons'
import { FC, useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import useAxios from 'axios-hooks'
import { getLocalStorage, setLocalStorage } from '../helpers/utils'

export const NavBar: FC = () => {
  let history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)
  const [agencyOwner, setAgencyOwner] = useState(false)
  const [, deleteSession] = useAxios(
    { url: '/api/session', method: 'DELETE' },
    { manual: true }
  )

  function updateUser() {
    setAgencyOwner(getLocalStorage('user')?.agencyOwner)
  }

  useEffect(() => {
    updateUser()
    window.addEventListener('update-user', updateUser)

    return () => {
      window.removeEventListener('update-user', updateUser)
    }
  }, [])

  const open = Boolean(anchorEl)

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const visit = (url: string) => {
    history.push(url)
    handleClose()
  }

  const logout = async () => {
    setAnchorEl(null)
    await deleteSession()
    setLocalStorage('loggedIn', false)
    setLocalStorage('user', null)
    history.push('/login')
  }

  const activeNavLinkClasses = 'text-white bg-p_green hover:bg-p_green'
  const navLinkClasses =
    'ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-white hover:bg-p_green focus:outline-none focus:p_green transition duration-150 ease-in-out'

  return (
    <nav className="bg-p_blue2 min-w-[1024px]">
      <div className="px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex">
            <img
              width="32"
              height="32"
              className="block h-8 w-auto mr-3"
              src="/assets/logo_square.png"
              alt="PracticallyMagic Logo"
            />
            {agencyOwner && (
              <NavLink
                activeClassName={activeNavLinkClasses}
                className={navLinkClasses}
                to="/agents"
              >
                Agents
              </NavLink>
            )}
            <NavLink
              activeClassName={activeNavLinkClasses}
              className={navLinkClasses}
              to="/trips"
            >
              Trips
            </NavLink>
            <NavLink
              activeClassName={activeNavLinkClasses}
              className={navLinkClasses}
              to="/content"
            >
              Content Library
            </NavLink>
            <NavLink
              activeClassName={activeNavLinkClasses}
              className={navLinkClasses}
              to="/store"
            >
              Itinerary Store
            </NavLink>
          </div>

          <div className="justify-end">
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              className="focus:outline-none"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle className="text-white" fontSize="large" />
            </IconButton>
            <Menu
              className="mt-8"
              id="menu-appbar"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={() => visit('/settings')}>My Account</MenuItem>
              <MenuItem onClick={() => visit('/agency')}>
                Agency Details
              </MenuItem>
              <MenuItem onClick={() => visit('/billing')}>
                Billing Information
              </MenuItem>
              <Divider />
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </nav>
  )
}
