import cx from 'classnames'
import React, { FC } from 'react'
import {
  pdfTitleClasses,
} from '../../helpers/pdf'
import { getDayOfWeek, getMonthAndDay } from '../../helpers/utils'
import { Day } from '../../types/day'

export const PDFDayOverview: FC<{
  day: Day
  priColor: string
  secColor: string
  fontSize: string
}> = ({ day, priColor, secColor, fontSize }) => {
  // Keep overview details base size or larger, like before font size was adjustable
  fontSize = fontSize === 'sm' ? 'base' : fontSize

  return (
    <div style={{ breakInside: 'avoid-column' }} className="break-inside-avoid">
      <div className={cx(pdfTitleClasses, 'flex space-x-2')}>
        <span style={{ color: priColor }}>
          {getDayOfWeek(day.date)}
        </span>
        <span className="text-pdf-divider">|</span>
        <span style={{ color: secColor }}>
          {getMonthAndDay(day.date)}
        </span>
      </div>
      {day.items
        .filter((item) => item.isTimeBased())
        .map((item) => (
          <div
            key={item.id}
            className={`flex font-light space-x-2 text-black-800 leading-tight text-${fontSize}`}
          >
            <div className="text-right" style={{minWidth: "4.5em"}}>
              {item.getTime(day.date)}
            </div>
            <div>{item.pdfTitle(day.date)}</div>
          </div>
        ))}
    </div>
  )
}
