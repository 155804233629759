import ReactDOM from 'react-dom';

import './theme/preflight.css'
import './theme/tailwind.css'
import './theme/layout.css'

import App from './App';
import {Honeybadger, HoneybadgerErrorBoundary} from '@honeybadger-io/react'

const honeybadger = Honeybadger.configure({
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
  environment: process.env.REACT_APP_HEROKU_APP_NAME === "practically-magic" ? "production" : "staging",
  revision: process.env.REACT_APP_SOURCE_VERSION
})

ReactDOM.render(
  <HoneybadgerErrorBoundary honeybadger={honeybadger}>
    <App />
  </HoneybadgerErrorBoundary>, 
  document.getElementById('root')
)
