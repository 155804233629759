import { FC, MouseEvent } from 'react'
import { Link as MuLink } from '@material-ui/core'
import { Link } from 'react-router-dom'

type SnackBarLinkProps = {
  onClick?: () => void
  to?: string
}

export const SnackBarLink: FC<SnackBarLinkProps> = ({
  onClick,
  to,
  children,
}) => {
  const handleOnClick = (event: MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    onClick && onClick()
  }

  if (to) {
    return (
      <Link to={to} className="text-p_teal hover:underline">
        {children}
      </Link>
    )
  } else {
    return (
      <MuLink
        href="#"
        onClick={handleOnClick}
        className="text-p_teal hover:underline"
      >
        {children}
      </MuLink>
    )
  }
}
