import { LocalizationProvider } from '@material-ui/lab'
import DateFnsAdapter from '@material-ui/lab/AdapterDateFns'
import { CloudinaryContext } from 'cloudinary-react'
import { MuiThemeProvider, StylesProvider } from '@material-ui/core'
import { QueryParamProvider } from 'use-query-params'
import enLocale from 'date-fns/locale/en-US'
import { FC, Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ConfirmationServiceProvider } from './components/ui/ConfirmationService'
import { NotificationServiceProvider } from './components/ui/NotificationService'
import { SnackBarServiceProvider } from './components/ui/SnackBarService'
import { OurBrowserRouter, setupAxios } from './helpers/app_setup'
import { AcceptInvite } from './pages/account/AcceptInvite'
import { AgencyPage } from './pages/account/AgencyPage'
import { ForgotPassword } from './pages/account/ForgotPassword'
import { Login } from './pages/account/Login'
import { ResetPassword } from './pages/account/ResetPassword'
import { BillingRedirect } from './pages/account/BillingRedirect'

/* Theme variables */
import { muiTheme } from './theme/mui_theme'
import './theme/mui_overrides.css'
import PublicItinerary from 'pages/PublicItinerary'

/* Lazy-loaded pages */
const TripsPage = lazy(() => import("./pages/trips"))
const BillingPage = lazy(() => import("./pages/account/BillingPage"))
const SettingsPage = lazy(() => import("./pages/account/SettingsPage"))
const SignUp = lazy(() => import("./pages/account/SignUp"))
const AgentsPage = lazy(() => import("./pages/AgentsPage"))
const ContentLibraryPage = lazy(() => import("./pages/ContentLibraryPage"))
const StorePage = lazy(() => import("./pages/StorePage"))
const ItineraryPage = lazy(() => import("./pages/ItineraryPage"))
const PDF = lazy(() => import("./pages/PDF"))

setupAxios()

const App: FC = () => (
  <MuiThemeProvider theme={muiTheme}>
    <Suspense fallback={<span>Loading…</span>}>
      <OurBrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <LocalizationProvider dateAdapter={DateFnsAdapter} locale={enLocale}>
            <StylesProvider injectFirst>
              <SnackBarServiceProvider>
                <NotificationServiceProvider>
                  <ConfirmationServiceProvider>
                    <CloudinaryContext cloudName="hppfmwc6d">
                      <div className="flex flex-col">
                        <Switch>
                          <Route path="/itinerary/:id/:title" component={ItineraryPage} exact={true} />
                          <Route path="/pdf/:id" component={PDF} />
                          <Route path="/view/:id" component={PublicItinerary} />
                          <Route path="/trips" component={TripsPage} />
                          <Route path="/agents" component={AgentsPage} />
                          <Route path="/content" component={ContentLibraryPage} />
                          <Route path="/store" component={StorePage} />
                          <Route path="/settings" component={SettingsPage} />
                          <Route path="/billing" component={BillingPage} />
                          <Route path="/manage-billing" component={BillingRedirect} />
                          <Route path="/agency" component={AgencyPage} />
                          <Route path="/login" component={Login} exact={true} />
                          <Route path="/sign-up" component={SignUp} exact={true} />
                          <Route path="/forgot-password" component={ForgotPassword} exact={true} />
                          <Route path="/reset-password" component={ResetPassword} exact={true} />
                          <Route path="/accept-invite" component={AcceptInvite} exact={true} />
                          <Route exact path="/" render={() => <Redirect to="/login" />} />
                        </Switch>
                      </div>
                    </CloudinaryContext>
                  </ConfirmationServiceProvider>
                </NotificationServiceProvider>
              </SnackBarServiceProvider>
            </StylesProvider>
          </LocalizationProvider>
        </QueryParamProvider>
      </OurBrowserRouter>
    </Suspense>
  </MuiThemeProvider>
)

export default App
