import { isSameDay } from 'date-fns'

import { FormFieldDescription } from '../../components/forms/FormBuilder'
import {
  anyDayMatch,
  onOrAfterDay,
} from '../../components/itinerary/items/helpers/utils'
import { displayTime } from '../../helpers/utils'
import { Trip } from '../trip'
import {
  Activity,
  Air,
  Attraction,
  Cruise,
  Ground,
  Lodging,
  Notes,
  Restaurant,
  Ticket,
  FullDay,
} from './'

export enum ItemType {
  ACTIVITY = 'activity',
  AIR = 'air',
  ATTRACTION = 'attraction',
  CRUISE = 'cruise',
  RESTAURANT = 'restaurant',
  GROUND = 'ground',
  LODGING = 'lodging',
  TICKET = 'ticket',
  NOTES = 'notes',
  FULL_DAY = 'full_day',
}

export interface ItineraryItemInterface {
  id: number
  notes?: string
  notes2?: string
  confirmation?: string
  link?: string
}

export interface UnsavedItineraryItemInterface {
  type: ItemType
  notes?: string
  notes2?: string
}

export interface BuildItineraryItemInterface extends ItineraryItemInterface {
  type: ItemType
}

export interface ItineraryItem extends ItineraryItemInterface {
  hidden?: boolean
}

export abstract class ItineraryItem {
  static apiPath = 'items'
  abstract readonly type: ItemType

  protected constructor(args: ItineraryItemInterface) {
    Object.assign(this, args)
  }

  get fullId() {
    if (this.type === 'notes') {
      return `note/${this.id}`
    }
    return `${this.type}/${this.id}`
  }

  // Names of start & end date/times, e.g. "Check in" or "Departure".
  abstract get startName(): string

  get endName(): string | null {
    return null
  }

  abstract get title(): string

  // belongs to.
  protected abstract get dates(): Date[]

  // Returns a list of dates this item is set for.
  // This is useful for needsReconfirm to check if there are any dates
  // associated with this item that do not align with the day the item

  // Most items will just use
  protected get times(): Date[] {
    return this.dates
  }

  // This is just like dates above EXCEPT it is for things with a timestamp AND
  // NOT used by needsReconfirm. For most items this will be the same as dates.
  // Notable exceptions  are activities and attractions, as they may have a time
  // but never require reconfirmation.

  static build(args: BuildItineraryItemInterface) {
    if (args.type === ('note' as ItemType)) {
      args.type = ItemType.NOTES
    }
    switch (args.type) {
      case ItemType.AIR:
        return new Air(args as any)
      case ItemType.CRUISE:
        return new Cruise(args as any)
      case ItemType.GROUND:
        return new Ground(args as any)
      case ItemType.TICKET:
        return new Ticket(args as any)
      case ItemType.ACTIVITY:
        return new Activity(args as any)
      case ItemType.ATTRACTION:
        return new Attraction(args as any)
      case ItemType.RESTAURANT:
        return new Restaurant(args as any)
      case ItemType.LODGING:
        return new Lodging(args as any)
      case ItemType.NOTES:
        return new Notes(args as any)
      case ItemType.FULL_DAY:
        return new FullDay(args as any)
    }
  }

  static itineraryFormFields(trip: Trip): FormFieldDescription[] {
    return []
  }

  static itineraryOnlyFormFields(trip: Trip): FormFieldDescription[] {
    return this.itineraryFormFields(trip)
  }

  static itineraryWithContentFormFields(trip: Trip): FormFieldDescription[] {
    return this.itineraryFormFields(trip)
  }

  isSecondDate(date: Date) {
    return this.dates.length === 2 && onOrAfterDay(date, this.dates[1])
  }

  isSameDepAndArrivalDay() {
    const dateFromDeparture = this.dates[0]?.toDateString()
    const dateFromArrival = this.dates[1]?.toDateString()

    if (dateFromDeparture === dateFromArrival) {
      const timeFromDepartureDate = this.dates[0]?.toLocaleTimeString('en-US', {
        hour12: false,
      })
      const timeFromArrivalDate = this.dates[1]?.toLocaleTimeString('en-US', {
        hour12: false,
      })

      //** true will equal Departure value in (e.g CruiseItem.tsx component) */
      return timeFromDepartureDate < timeFromArrivalDate
    }
    return false
  }

  getTime(date: Date) {
    if (this.dates.length === 2 && isSameDay(this.dates[1], date)) {
      return displayTime(this.times[1])
    } else {
      return displayTime(this.times[0])
    }
  }

  needsReconfirm(date: Date) {
    if (this.dates.length === 0) return false
    return !anyDayMatch(date, this.dates)
  }

  abstract isTimeBased(): boolean

  pdfTitle(date: Date) {
    return this.title
  }

  pdfItemTitle(date: Date) {
    return this.pdfTitle(date)
  }

  isSavable(): boolean {
    return true
  }
}
