import { Button, Dialog } from '@material-ui/core'
import { FC, ReactNode } from 'react'

export interface NotificationOptions {
  title: string
  description: ReactNode
}

interface NotificationDialogProps extends NotificationOptions {
  open: boolean
  onClose: () => void
}

export const NotificationDialog: FC<NotificationDialogProps> = ({
  open,
  title,
  description,
  onClose,
}) => {
  return (
    <Dialog open={open} classes={{ paper: 'p-5 space-y-5' }}>
      <h2 className="text-xl">{title}</h2>
      <div>{description}</div>
      <div className="text-right space-x-2">
        <Button onClick={onClose} color="primary" variant="contained">
          OK
        </Button>
      </div>
    </Dialog>
  )
}
