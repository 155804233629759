import { FormFieldTypes } from '../../components/forms/FormBuilder'
import { last } from '../../helpers/utils'
import { Trip } from '../trip'
import { ItemType, ItineraryItem, ItineraryItemInterface } from './base'

type Connection = {
  id: number
  flightNumber: string
  departureAt: Date
  departureCity: string
  arrivalAt: Date
  arrivalCity: string
  _destroy: boolean
}

interface AirInterface extends ItineraryItemInterface {
  airline: string
  connections: Connection[]
}

export interface Air extends AirInterface {}
export class Air extends ItineraryItem {
  type = ItemType.AIR

  constructor(args: ItineraryItemInterface) {
    super(args)
    this.connections.forEach((connection) => (connection._destroy = false))
  }

  protected get dates() {
    return [this.connections[0].departureAt]
  }

  static itineraryFormFields(trip: Trip) {
    return [
      { name: 'airline', type: FormFieldTypes.STRING, required: true },
      { name: 'confirmation', type: FormFieldTypes.STRING, required: true },
      { name: 'notes', type: FormFieldTypes.TEXTAREA, required: false },

      {
        name: 'connections',
        label: 'Flights',
        type: FormFieldTypes.ARRAY,
        required: true,
        fields: [
          { name: 'flightNumber', type: FormFieldTypes.STRING, required: true },
          {
            name: 'departureAt',
            type: FormFieldTypes.DATE_TIME,
            required: true,
          },
          {
            name: 'departureCity',
            type: FormFieldTypes.STRING,
            required: true,
          },
          { name: 'arrivalAt', type: FormFieldTypes.DATE_TIME, required: true },
          { name: 'arrivalCity', type: FormFieldTypes.STRING, required: true },
          { name: 'id', type: FormFieldTypes.HIDDEN },
          { name: '_destroy', type: FormFieldTypes.HIDDEN },
        ],
      },
    ]
  }

  get firstConnection(): Connection {
    return this.connections[0]
  }

  get lastConnection(): Connection {
    return last(this.connections)
  }

  get startName() {
    return 'Departure'
  }

  get title() {
    return this.airline
  }

  pdfTitle(date: Date): string {
    return `Flight to ${last(this.connections).arrivalCity}`
  }

  isTimeBased() {
    return true
  }

  isSavable() {
    return false
  }
}
