import { FC } from 'react'
import { displayTime } from '../../../helpers/utils'
import { Activity } from '../../../types/items/'
import { PDFItemDetail, PDFItemDetails } from './helpers/PDFItemDetail'

export const PDFActivityItem: FC<{ item: Activity; date: Date }> = ({
  item,
  date,
}) => {
  return (
    <>
      <PDFItemDetails>
        <PDFItemDetail field="Time" value={displayTime(item.startAt!)} />
        <PDFItemDetail field="Confirmation" value={item.confirmation} />
      </PDFItemDetails>
      <PDFItemDetails>
        <PDFItemDetail field="Location" value={item.location} />
      </PDFItemDetails>
    </>
  )
}
