import { ClickAwayListener, TextField } from '@material-ui/core'
import { DatePicker } from '@material-ui/lab'
import PickersDay from '@material-ui/lab/PickersDay'
import { isAfter, isBefore, isSameDay } from 'date-fns'
import { FC, useState } from 'react'
import { Controller } from 'react-hook-form'
import { displayDate } from '../../../helpers/utils'
import { get } from 'lodash'
import { BasicTextFieldProp } from './BasicTextField'

interface DateFieldProps {
  className?: string
  errors?: any
  setError: (name: string, error: any) => void
  clearErrors: (name: string) => void
  control: any
  datePickerProps?: any
  needsReconfirm: boolean
  disabled?: boolean
}

export const DateField: FC<BasicTextFieldProp & DateFieldProps> = ({
  className,
  required,
  name,
  label,
  helperText,
  needsReconfirm,
  errors,
  setError,
  clearErrors,
  control,
  value,
  datePickerProps,
  disabled,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const reconfirmMessage = needsReconfirm
    ? 'Double check date and hit save to reconfirm.'
    : null
  const getProps = (props: any) => {
    if (!errorMessage && needsReconfirm) {
      props.InputProps.classes = { notchedOutline: 'border-blue-600' }
      return {
        ...props,
        InputLabelProps: { classes: { root: 'text-blue-600' } },
        FormHelperTextProps: { classes: { root: 'text-blue-600' } },
      }
    } else {
      return props
    }
  }

  const minDate = displayDate(datePickerProps?.minDate as Date)
  const maxDate = displayDate(datePickerProps?.maxDate as Date)
  const currentDay = datePickerProps?.currentDay

  const updateError = (reason: string | null) => {
    switch (reason) {
      case 'minDate':
        setErrorMessage(`Cannot be before ${minDate}.`)
        break
      case 'maxDate':
        setErrorMessage(`Cannot be after ${maxDate}.`)
        break
      case 'invalidDate':
      default:
        setErrorMessage(null)
    }
  }

  const validate = (date: Date) => {
    if (!date) {
      return !required
    }

    if (datePickerProps?.minDate) {
      const minDate = datePickerProps.minDate as Date
      if (!isSameDay(date, minDate) && isBefore(date, minDate)) {
        return false
      }
    }

    if (datePickerProps?.maxDate) {
      const maxDate = datePickerProps.maxDate as Date
      if (!isSameDay(date, maxDate) && isAfter(date, maxDate)) {
        return false
      }
    }

    return true
  }

  const error = get(errors, name)
  const rules = required
    ? { required: `${label} is required.`, validate }
    : { validate }

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className={className}>
        <Controller
          key={name}
          defaultValue={
            value ||
            datePickerProps?.defaultValue ||
            null /* null to avoid undefined */
          }
          rules={rules}
          as={
            <DatePicker
              {...datePickerProps}
              defaultCalendarMonth={currentDay}
              renderDay={(day: Date, selectedDates: [Date], dayProps: any) => {
                return (
                  <PickersDay
                    {...dayProps}
                    today={isSameDay(day, currentDay)}
                  />
                )
              }}
              disabled={disabled}
              clearable
              open={isOpen}
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
              onError={(reason) => updateError(reason as string | null)}
              label={label}
              onChange={() => {}} // Overwritten by wrapping Controller.
              renderInput={(props) => {
                return (
                  <TextField
                    className="w-full"
                    // onClick={() => setIsOpen(true)}
                    {...getProps(props)}
                    error={!!error}
                    required={required}
                    helperText={
                      error?.message ||
                      errorMessage ||
                      reconfirmMessage ||
                      helperText ||
                      props.helperText
                    }
                    variant="outlined"
                  />
                )
              }}
            />
          }
          control={control}
          name={name}
        />
      </div>
    </ClickAwayListener>
  )
}
