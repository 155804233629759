import { Button } from '@material-ui/core'
import { FC, useState } from 'react'
import { getLocalStorage, useEffectOnce } from '../helpers/utils'

export const ImpersonatingBar: FC = () => {
  const [impersonating, setImpersonating] = useState<any>()

  useEffectOnce(async () => {
    // Check if we are impersonating someone.
    const userData = getLocalStorage('user')
    if (userData?.impersonating) {
      setImpersonating(userData.email)
    }
  })

  const stopImpersonating = async () => {
    // Dev runs react app on 3000 and rails on 3001.
    if (process.env.NODE_ENV === 'development') {
      window.location.href = '//localhost:3001/stop_impersonating'
    } else {
      window.location.pathname = '/stop_impersonating'
    }
  }

  if (!impersonating) return null

  return (
    <div className="bg-p_yellow py-1 text-center text-sm font-bold text-black-700 min-w-[1024px]">
      Impersonating{' '}
      <a
        href={`mailto:${impersonating}`}
        className="text-blue-500 hover:underline"
      >
        {impersonating}
      </a>
      <Button
        size="small"
        color="secondary"
        variant="contained"
        className="ml-2 py-0 min-w-0"
        onClick={stopImpersonating}
      >
        Stop
      </Button>
    </div>
  )
}
