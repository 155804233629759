import { FC } from 'react'
import { displayTime } from '../../../helpers/utils'
import { Ticket } from '../../../types/items/'
import { PDFItemDetail, PDFItemDetails } from './helpers/PDFItemDetail'

export const PDFTicketItem: FC<{ item: Ticket; date: Date }> = ({
  item,
  date,
}) => {
  return (
    <>
      <PDFItemDetails>
        <PDFItemDetail field="Time" value={displayTime(item.startAt!)} />
        <PDFItemDetail field="Confirmation" value={item.confirmation} />
      </PDFItemDetails>
    </>
  )
}
