import { createContext, FC, ReactNode, useContext, useState } from 'react'
import { SnackBarMessage, SnackBarOptions } from './SnackBarMessage'

const SnackBarServiceContext = createContext<
  (options: SnackBarOptions) => void
>(() => {})

export const useSnackBar = () => useContext(SnackBarServiceContext)

export const SnackBarServiceProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [snackBarState, setSnackBarState] = useState<SnackBarOptions>({
    message: '',
  })

  const [isOpen, setIsOpen] = useState(false)

  const openSnackBar = (options: SnackBarOptions) => {
    setSnackBarState(options)
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <SnackBarServiceContext.Provider
        value={openSnackBar}
        children={children}
      />

      <SnackBarMessage open={isOpen} onClose={handleClose} {...snackBarState} />
    </>
  )
}
