import {Trip} from '../trip'
import {ItemType, ItineraryItem, ItineraryItemInterface} from './base'
import {itemTemplateFields} from "../content/template";

interface NotesInterface extends ItineraryItemInterface {
  name: string
  pickUpAt: Date
  pickUpLocation: string
  dropOffLocation: string
}

export interface Notes extends NotesInterface {}
export class Notes extends ItineraryItem {
  type = ItemType.NOTES

  protected get dates() {
    return this.pickUpAt ? [this.pickUpAt] : []
  }

  static itineraryFormFields(trip: Trip) {
    return []
  }

  static itineraryOnlyFormFields(trip: Trip) {
    return itemTemplateFields()
  }

  get startName() {
    return 'Pick Up'
  }

  get title() {
    return this.name
  }

  pdfTitle(date: Date): string {
    return `${this.title}`
  }

  isTimeBased() {
    return !!this.pickUpAt
  }
}
