import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
} from '@material-ui/core'
import { Image } from 'cloudinary-react'
import { FC, useState } from 'react'
import { getLocalStorage } from '../../../helpers/utils'
import { BasicTextFieldProp } from './BasicTextField'

export const ImageField: FC<BasicTextFieldProp> = ({
  errors,
  name,
  label,
  value,
  helperText,
  required,
  register,
}) => {
  const [currentValue, setCurrentValue] = useState(value)
  const userId = getLocalStorage('user')?.id

  // @ts-ignore
  const cloudinaryUploader = cloudinary

  const myWidget = cloudinaryUploader.createUploadWidget(
    {
      cloudName: 'hppfmwc6d',
      uploadPreset: 'clientside',
      sources: ['local', 'url', 'dropbox', 'instagram', 'google_drive'],
      multiple: false,
      clientAllowedFormats: ['png', 'jpg', 'jpeg', 'svg'],
      maxFileSize: 2000000, // 2 MB
      showPoweredBy: false,
      // autoMinimize: true,
      singleUploadAutoClose: true,
      tags: [label, `user-${userId}`],
    },
    (error: any, result: any) => {
      if (!error && result && result.event === 'success') {
        setCurrentValue(result.info.public_id)
      }
    }
  )

  const handleDelete = () => {
    setCurrentValue('')
  }

  return (
    <FormControl className="space-y-3">
      <FormLabel component="legend">{label}</FormLabel>
      {currentValue && (
        <div className="w-auto">
          <Image publicId={currentValue} className="max-h-36" />
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            className="w-full rounded-none"
          >
            Remove
          </Button>
        </div>
      )}
      {!currentValue && (
        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => myWidget.open()}
          >
            Add {label}
          </Button>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </div>
      )}
      <input
        className="hidden"
        value={currentValue || ''}
        name={name}
        ref={register({ required })}
        type="hidden"
      />
    </FormControl>
  )
}
