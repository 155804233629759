import { FormFieldTypes } from '../../components/forms/FormBuilder'
import { itemTemplateFields } from '../content/template'
import { Trip } from '../trip'
import { ItemType, ItineraryItem, ItineraryItemInterface } from './base'

interface ActivityInterface extends ItineraryItemInterface {
  fastPassEnabled: boolean
  dasPassEnabled: boolean
  location: string
  name: string
  startAt?: Date
  tags: string[]
}

export interface Activity extends ActivityInterface {}
export class Activity extends ItineraryItem {
  type = ItemType.ACTIVITY

  protected get dates() {
    return this.startAt ? [this.startAt] : []
  }

  static itineraryOnlyFormFields(trip: Trip) {
    return [
      {
        name: 'passes',
        type: FormFieldTypes.ROW,
        fields: [
          {
            name: 'fastPassEnabled',
            label: 'Lightning Lane',
            type: FormFieldTypes.SWITCH,
            exclusiveWith: 'dasPassEnabled'
          },
          {
            name: 'dasPassEnabled',
            label: 'Disability Assistance Service (DAS)',
            type: FormFieldTypes.SWITCH,
            exclusiveWith: 'fastPassEnabled'
          },
        ],
      },
      { name: 'startAt', type: FormFieldTypes.DATE_TIME, required: false },
      { name: 'confirmation', type: FormFieldTypes.STRING, required: false },
      ...itemTemplateFields(),
    ]
  }

  static itineraryWithContentFormFields(trip: Trip) {
    return [
      {
        name: 'passes',
        type: FormFieldTypes.ROW,
        fields: [
          {
            name: 'fastPassEnabled',
            label: 'Lightning Lane',
            type: FormFieldTypes.SWITCH,
            exclusiveWith: 'dasPassEnabled'
          },
          {
            name: 'dasPassEnabled',
            label: 'Disability Assistance Service (DAS)',
            type: FormFieldTypes.SWITCH,
            exclusiveWith: 'fastPassEnabled'
          },
        ],
      },
      { name: 'startAt', type: FormFieldTypes.DATE_TIME, required: false },
      { name: 'confirmation', type: FormFieldTypes.STRING, required: false },
    ]
  }

  isTimeBased() {
    return !!this.startAt
  }

  get startName() {
    return this.flag.length ? this.flag :'Time'
  }

  get title() {
    return this.name
  }

  get flag() {
    if (this.fastPassEnabled) {
      return 'Lightning Lane'
    }
    if (this.dasPassEnabled) {
      return 'DAS'
    }
    return '';
  }

  get passEnabled() {
    return this.fastPassEnabled || this.dasPassEnabled
  }

  pdfTitle(date: Date): string {
    return this.passEnabled ? `${this.title} ${this.flag}` : this.title
  }

  pdfItemTitle(date: Date): string {
    return this.passEnabled ? `${this.flag}: ${this.title}` : this.title
  }
}
