import { Button, Dialog } from '@material-ui/core'
import { FC } from 'react'

export interface ConfirmationOptions {
  title: string
  description: string
  confirmButton?: string
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean
  onSubmit: () => void
  onClose: () => void
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open,
  title,
  description,
  confirmButton,
  onSubmit,
  onClose,
}) => {
  return (
    <Dialog open={open} classes={{ paper: 'p-5 space-y-5' }}>
      <h2 className="text-xl">{title}</h2>
      <div>{description}</div>
      <div className="text-right space-x-2">
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="secondary" variant="outlined">
          {confirmButton || 'Delete'}
        </Button>
      </div>
    </Dialog>
  )
}
