import { FormFieldTypes } from '../../components/forms/FormBuilder'
import { itemTemplateFields } from '../content/template'
import { Trip } from '../trip'
import { ItemType, ItineraryItem, ItineraryItemInterface } from './base'

interface RestaurantInterface extends ItineraryItemInterface {
  name: string
  location: string
  reservation?: Date
  tags: string[]
}
export interface Restaurant extends RestaurantInterface {}
export class Restaurant extends ItineraryItem {
  type = ItemType.RESTAURANT

  protected get dates() {
    return this.reservation ? [this.reservation] : []
  }

  static itineraryOnlyFormFields(trip: Trip) {
    return [
      { name: 'reservation', type: FormFieldTypes.DATE_TIME, required: false },
      { name: 'confirmation', type: FormFieldTypes.STRING, required: false },
      ...itemTemplateFields(),
    ]
  }

  static itineraryWithContentFormFields(trip: Trip) {
    return [
      { name: 'reservation', type: FormFieldTypes.DATE_TIME, required: false },
      { name: 'confirmation', type: FormFieldTypes.STRING, required: false },
    ]
  }

  isTimeBased() {
    return !!this.reservation
  }

  get startName() {
    return 'Reservation'
  }

  get title() {
    return this.name
  }
}
