import { FC } from 'react'
import { displayTime } from '../../../helpers/utils'
import { Restaurant } from '../../../types/items/'
import { PDFItemDetail, PDFItemDetails } from './helpers/PDFItemDetail'

export const PDFRestaurantItem: FC<{ item: Restaurant; date: Date }> = ({
  item,
  date,
}) => {
  return (
    <>
      <PDFItemDetails>
        <PDFItemDetail
          field="Reservation"
          value={displayTime(item.reservation)}
        />
        <PDFItemDetail field="Confirmation" value={item.confirmation} />
      </PDFItemDetails>
      <PDFItemDetails>
        <PDFItemDetail field="Location" value={item.location} />
      </PDFItemDetails>
    </>
  )
}
