import { FormFieldTypes } from '../../components/forms/FormBuilder'
import { onOrAfterDay } from '../../components/itinerary/items/helpers/utils'
import { Trip } from '../trip'
import { ItemType, ItineraryItem, ItineraryItemInterface } from './base'
import { itemTemplateFields } from '../content/template'

interface CruiseInterface extends ItineraryItemInterface {
  name: string
  roomType?: string
  roomNumber?: string
  arrivalAt: Date
  arrivalPort: string
  departureAt: Date
  departurePort: string
}

export interface Cruise extends CruiseInterface {}
export class Cruise extends ItineraryItem {
  type = ItemType.CRUISE

  protected get dates() {
    return [this.departureAt, this.arrivalAt]
  }

  static itineraryFormFields(trip: Trip) {
    return [
      { name: 'roomType', type: FormFieldTypes.STRING, required: false },
      { name: 'roomNumber', type: FormFieldTypes.STRING, required: false },
      { name: 'departureAt', type: FormFieldTypes.DATE_TIME, required: true },
      { name: 'departurePort', type: FormFieldTypes.STRING, required: true },
      { name: 'arrivalAt', type: FormFieldTypes.DATE_TIME, required: true },
      { name: 'arrivalPort', type: FormFieldTypes.STRING, required: true },
      { name: 'confirmation', type: FormFieldTypes.STRING, required: true },
    ]
  }

  static itineraryOnlyFormFields(trip: Trip) {
    return [
      ...this.itineraryFormFields(trip),
      {
        name: 'notes',
        label: 'Notes for Departure',
        type: FormFieldTypes.TEXTAREA,
        required: false,
      },
      {
        name: 'notes2',
        label: 'Notes for Arrival',
        type: FormFieldTypes.TEXTAREA,
        required: false,
      },
      ...itemTemplateFields(false, true),
    ]
  }

  get startName() {
    return 'Departure'
  }

  get endName() {
    return 'Arrival'
  }

  get title() {
    return this.name
  }

  pdfTitle(date: Date) {
    const postfix = this.isSecondDate(date) ? this.endName : this.startName
    return `${this.title} ${postfix}`
  }

  isTimeBased() {
    return true
  }

  showDeparture(date: Date) {
    return !onOrAfterDay(date, this.arrivalAt)
  }
}
