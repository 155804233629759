import { makeStyles } from '@material-ui/styles'
import cx from 'classnames'
import axios from 'axios'
import { Image } from 'cloudinary-react'
import { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { PublicDay } from '../components/public/PublicDay'
import { PDFDayOverview } from '../components/pdf/pdf_day_overview'
import { SvgGradientText } from '../components/ui/SvgGradientText'
import { pdfHeadingClasses, } from '../helpers/pdf'
import { displayDate, getApiUrl, getMonthAndDay } from '../helpers/utils'
import { Trip } from '../types/trip'
import { CircularProgress, Drawer, Fab, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import AddToHome from '../components/ui/AddToHome'

interface PDFProps extends RouteComponentProps<{ id: string }> {}

const useStyles = makeStyles({
  trial: {
    position: 'relative',
    '&:before': {
      backgroundImage: 'url(/assets/logo.png)',
      backgroundSize: '150px',
      backgroundRepeat: 'space space',
      content: "''",
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      opacity: 0.1,
      zIndex: -1,
      filter: 'grayscale(1)',
    },
  },
  root: {
    '& a': {
      color: 'blue',
    }
  },
})

export const PublicItinerary: FC<PDFProps> = ({
  match: {
    params: { id },
  },
}) => {
  const [trip, setTrip] = useState<Trip>()
  const [logo, setLogo] = useState()
  const [trialing, setTrialing] = useState<boolean>()
  const [colors, setColors] = useState(["#000000", "#000000"])
  const [pageBreaks, setPageBreaks] = useState<boolean>(true)
  const [fontSize, setFontSize] = useState<string>('sm')
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const classes = useStyles()

  useEffect(() => {
    axios
      .get(getApiUrl(Trip.apiPath, id, 'pdf'))
      .then(({ data: { trip, logo, trialing, colors, pageBreaks, fontSize } }) => {
        setTrip(Trip.build(trip))
        setLogo(logo)
        setTrialing(trialing)
        setColors(colors)
        setPageBreaks(pageBreaks)
        setFontSize(fontSize)
      })
  }, [id])

  if (!trip) {
    return (
      <div className="flex h-screen">
        <div className="m-auto text-center">
          <CircularProgress color="secondary" />
          <p className="text-lg">Loading itinerary...</p>
        </div>
      </div>
    )
  }

  const colorStyles = `
    :root {
      --primary-color: ${colors[0]};
      --secondary-color: ${colors[1]};
    }
  `

  return (
    <div
      className={cx(
        'p-2 sm:p-4 md:p-8 lg:px-14 lg:py-10 trial-bg',
        classes.root,
        trialing ? classes.trial : ''
      )}
    >
      <style>{colorStyles}</style>
      <link rel="manifest" href="/trip.webmanifest" />
      <link
        href="https://fonts.googleapis.com/css2?family=Saira+Extra+Condensed&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@400&display=swap"
        rel="stylesheet"
      />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        integrity="sha512-SfTiTlX6kk+qitfevl/7LibUOeJWlt9rbyDn92a1DqWOw9vWG2MFoays0sgObmWazO5BQPiFucnnEAjpAB+/Sw=="
        crossOrigin="anonymous"
      />
      <AddToHome />
      <Fab
        size="medium"
        aria-label="open menu"
        onClick={() => setMenuOpen(true)}
        className="fixed bottom-3 right-3"
      >
        <MenuIcon />
      </Fab>
      <Drawer
        anchor="right"
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        PaperProps={{className: "w-full sm:w-auto"}}
      >
        <List onClick={() => setMenuOpen(false)} className="flex flex-col h-full">
          <div className="flex-auto" onClick={() => setMenuOpen(false)}></div>
          <ListItem href="#overview" component="a" button key="overview">
            <ListItemIcon className="text-2xl mr-8"></ListItemIcon>
            <ListItemText disableTypography className="text-3xl">Trip Overview</ListItemText>
          </ListItem>
          {trip.daysOfTrip.map((day, i) => (
            <ListItem href={`#day-${day.position}`} component="a" button key={i}>
              <ListItemIcon className="text-xl mr-8">Day {day.position + 1}</ListItemIcon>
              <ListItemText>
                <div className="flex justify-between space-x-8">
                  <div className="text-2xl">{day.title}</div>
                  <span className="text-lg mt-2">
                    {getMonthAndDay(day.date)}
                  </span>
                </div>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <div className="space-y-12">
        <div className="space-y-6 sm:space-y-10">
          <div className="flex flex-col-reverse justify-center md:flex-row md:justify-between">
            <div className="inline-block text-center md:space-y-2">
              <div
                className="sm:p-0.5"
                style={{
                  backgroundImage: `linear-gradient(to right,${colors[0]}, ${colors[1]})`,
                }}
              >
                <div className="bg-white flex justify-center sm:px-6">
                  <SvgGradientText
                    text="trip overview"
                    textClass={pdfHeadingClasses}
                    color1={colors[0]}
                    color2={colors[1]}
                  />
                </div>
              </div>
              <div className="flex justify-center sm:mt-3 sm:px-6">
                <SvgGradientText
                  text={`${displayDate(trip.startAt)} — ${displayDate(trip.endAt)}`}
                  textClass="font-bold tracking-widest"
                  color1={colors[0]}
                  color2={colors[1]}
                />
              </div>
            </div>
            <Image publicId={logo} className="object-contain max-h-32 m-4 md:-mb-4" />
          </div>

          <div id="overview" className="flex">
            <div className="flex-grow space-y-5 md:columns-2 md:column-gap-10">
              {trip.daysOfTrip.map((day) => (
                <PDFDayOverview
                  key={day.id}
                  day={day}
                  priColor={colors[0]}
                  secColor={colors[1]}
                  fontSize={fontSize}
                />
              ))}
            </div>
          </div>
        </div>

        {trip.daysWithNotes.map((day) => (
          <PublicDay
            key={day.id}
            day={day}
            priColor={colors[0]}
            secColor={colors[1]}
            pageBreaks={pageBreaks}
            fontSize={fontSize}
          />
        ))}
      </div>
    </div>
  )
}

export default PublicItinerary
