import { isAfter, isSameDay } from 'date-fns'
import { StringMap } from '../../../../types/basic'

export function anyDayMatch(currentDay: Date, dates: Date[]) {
  for (const date of dates) {
    if (isSameDay(currentDay, date)) {
      return true
    }
  }
  return false
}

export function onOrAfterDay(currentDay: Date, date: Date) {
  return isSameDay(currentDay, date) || isAfter(currentDay, date)
}

export function locationToColor(location: string) {
  // Convert location to all lowercase and removes non a-z letters.
  if( location) {
    const cleanLocation = location.toLowerCase().replace(/[^a-z]/g, '')
    for (const [location, color] of Object.entries(locationColors)) {
      if (cleanLocation.includes(location)) {
        return color
      }
    }
  }
  return defaultColor
}

export const defaultColor = '#282D55' // p_blue2

const locationColors = {
  // Magic Kingdom
  fantasyland: 'rgb(218, 50, 118)',
  frontierland: 'rgb(155, 72, 56)',
  libertysquare: 'rgb(232, 143, 61)',
  adventureland: 'rgb(54, 124, 69)',
  mainstreetusa: 'rgb(30, 85, 160)',
  tomorrowland: 'rgb(111, 52, 139)',

  // Epcot
  futureworldwest: 'rgb(168, 194, 82)',
  futureworldeast: 'rgb(112, 139, 179)',
  showcaseplaza: 'rgb(223, 142, 61)',
  worldshowcase: 'rgb(151, 48, 95)',

  // Hollywood Studios
  hollywoodboulevard: 'rgb(118, 177, 177)',
  echolake: 'rgb(198, 45, 126)',
  grandavenue: 'rgb(35, 56, 117)',
  commissarylane: 'rgb(65, 149, 201)',
  toystoryland: 'rgb(232, 143, 61)',
  animationcourtyard: 'rgb(92, 162, 83)',
  sunsetboulevard: 'rgb(150, 50, 120)',

  // Animal Kingdom
  oasis: 'rgb(170, 99, 142)',
  discoveryisland: 'rgb(87, 117, 70)',
  pandoratheworldofavatar: 'rgb(92, 113, 154)',
  afria: 'rgb(192, 104, 64)',
  asia: 'rgb(119, 60, 130)',
  dinolandusa: 'rgb(116, 83, 65)',
} as StringMap
