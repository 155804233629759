import { FormFieldTypes } from '../../components/forms/FormBuilder'
import { itemTemplateFields } from '../content/template'
import { Trip } from '../trip'
import { ItemType, ItineraryItem, ItineraryItemInterface } from './base'

interface LodgingInterface extends ItineraryItemInterface {
  name: string
  roomType: string
  roomNumber: string
  checkInAt: Date
  checkOutAt: Date
  location: string
  tags: string[]
}
export interface Lodging extends LodgingInterface {}
export class Lodging extends ItineraryItem {
  type = ItemType.LODGING

  protected get dates() {
    return [this.checkInAt, this.checkOutAt]
  }

  static itineraryOnlyFormFields(trip: Trip) {
    return [
      { name: 'roomType', type: FormFieldTypes.STRING, required: false },
      { name: 'roomNumber', type: FormFieldTypes.STRING, required: false },
      {
        name: 'checkInAt',
        type: FormFieldTypes.DATE_TIME,
        required: true,
        fieldProps: {
          [FormFieldTypes.DATE]: {
            defaultValue: trip.startAt,
          },
          [FormFieldTypes.TIME]: {
            defaultValue: '3:00 PM',
          },
        },
      },
      {
        name: 'checkOutAt',
        type: FormFieldTypes.DATE_TIME,
        required: true,
        fieldProps: {
          [FormFieldTypes.DATE]: {
            defaultValue: trip.endAt,
          },
          [FormFieldTypes.TIME]: {
            defaultValue: '11:00 AM',
          },
        },
      },
      { name: 'confirmation', type: FormFieldTypes.STRING, required: true },
      ...itemTemplateFields(true),
    ]
  }

  static itineraryWithContentFormFields(trip: Trip) {
    return [
      { name: 'roomType', type: FormFieldTypes.STRING, required: false },
      { name: 'roomNumber', type: FormFieldTypes.STRING, required: false },
      {
        name: 'checkInAt',
        type: FormFieldTypes.DATE_TIME,
        required: true,
        fieldProps: {
          [FormFieldTypes.DATE]: {
            defaultValue: trip.startAt,
          },
          [FormFieldTypes.TIME]: {
            defaultValue: '3:00 PM',
          },
        },
      },
      {
        name: 'checkOutAt',
        type: FormFieldTypes.DATE_TIME,
        required: true,
        fieldProps: {
          [FormFieldTypes.DATE]: {
            defaultValue: trip.endAt,
          },
          [FormFieldTypes.TIME]: {
            defaultValue: '11:00 AM',
          },
        },
      },
      { name: 'confirmation', type: FormFieldTypes.STRING, required: true },
    ]
  }

  isTimeBased() {
    return true
  }

  get startName() {
    return 'Check In'
  }

  get endName() {
    return 'Check Out'
  }

  get title() {
    return this.name
  }

  pdfTitle(date: Date) {
    const prefix = this.isSecondDate(date) ? this.endName : this.startName
    return `${prefix} ${this.title}`
  }
}
