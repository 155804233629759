import { FC } from 'react'
import { displayTime } from '../../../helpers/utils'
import { Lodging } from '../../../types/items/'
import { PDFItemDetail, PDFItemDetails } from './helpers/PDFItemDetail'

export const PDFLodgingItem: FC<{ item: Lodging; date: Date }> = ({
  item,
  date,
}) => {
  const showCheckIn = !item.isSecondDate(date)
  const inOrOut = showCheckIn ? item.startName : item.endName
  const inOrOutTime = showCheckIn ? item.checkInAt : item.checkOutAt

  return (
    <>
      <PDFItemDetails>
        <PDFItemDetail field={inOrOut} value={displayTime(inOrOutTime)} />
        <PDFItemDetail
          className="ml-auto"
          field="confirmation"
          value={item.confirmation!}
        />
      </PDFItemDetails>
      {showCheckIn && (
        <PDFItemDetails>
          <PDFItemDetail field="Room Type" value={item.roomType} />
          <PDFItemDetail field="Room Number" value={item.roomNumber} />
        </PDFItemDetails>
      )}
      <PDFItemDetails>
        <PDFItemDetail field="Location" value={item.location} />
      </PDFItemDetails>
    </>
  )
}
