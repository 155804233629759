import { TextField } from '@material-ui/core'
import { TextFieldProps } from '@material-ui/core/TextField/TextField'
import { FC } from 'react'
import { get } from 'lodash'

interface BasicTextFieldInterface {
  errors: any
  name: string
  label: string
  value?: any
  helperText?: string
  required?: boolean
  validationProps?: any
  register: any
}
export type BasicTextFieldProp = BasicTextFieldInterface & TextFieldProps

export const BasicTextField: FC<BasicTextFieldProp> = ({
  errors,
  name,
  label,
  value,
  helperText,
  required,
  register,
  validationProps,
  ...other
}) => {
  const error = get(errors, name)
  const helper = error ? error.message || 'Invalid' : helperText

  // Note: We need to explicitly set required to false. This fixes a bug where
  // we could register a field as required and then re-register it as not required.
  // This happens for the fast pass time field.
  const registerProps = required
    ? { required: `${label} is required.`, ...validationProps }
    : { required: false, ...validationProps }

  return (
    <TextField
      {...other}
      error={!!error}
      inputRef={register(registerProps)}
      required={required}
      name={name}
      label={label}
      defaultValue={value}
      helperText={helper}
      variant="outlined"
    />
  )
}
