import { CircularProgress } from '@material-ui/core'
import axios from 'axios'
import { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { getApiUrl, getLocalStorage } from '../../helpers/utils'
import { Agent } from '../../types/agent'
import { AcceptInviteExistingUser } from './helpers/AcceptInviteExistingUser'
import { AcceptInviteNewUser } from './helpers/AcceptInviteNewUser'

interface AcceptInviteProps extends RouteComponentProps {}

/*
Accept Invite handles the following cases:
1. New user (must setup password).
2. Existing user that already belongs to an agency.
3. Existing user that has yet to join any agency.
4. Token is invalid or not present.
5. Invite expired.
6. Invite has already been accepted.
 */
export const AcceptInvite: FC<AcceptInviteProps> = ({ location }) => {
  const token = new URLSearchParams(location.search).get('token') || ''
  const loggedIn = getLocalStorage('loggedIn') && getLocalStorage('user')

  const [inviteInfo, setInviteInfo] = useState<any>()

  useEffect(() => {
    axios
      .get(getApiUrl(Agent.apiPath, null, `verify_invite_token?token=${token}`))
      .then(({ data }) => {
        setInviteInfo(data)
      })
  }, [token])

  if (!inviteInfo) {
    return (
      <div className="flex h-full items-center justify-center">
        <CircularProgress />
      </div>
    )
  }

  let message = null
  let canJoin = true
  if (inviteInfo.error === 'invalid') {
    canJoin = false
    message = (
      <span>
        Invitation not found, please contact the agency owner to get a new
        invite.
      </span>
    )
  } else if (inviteInfo.expired) {
    canJoin = false
    message = (
      <span>
        This invitation has expired, please contact the agency owner to get a
        new invite.
      </span>
    )
  } else if (inviteInfo.agencyOwner) {
    canJoin = false
    message = (
      <span>
        You cannot join another agency when you are already an agency owner.
      </span>
    )
  } else if (inviteInfo.acceptedAt) {
    canJoin = false
    message = (
      <span>
        You've already accepted this invite, continue to{' '}
        {loggedIn ? (
          <Link className="underline text-black" to="/trips">
            Your Trips
          </Link>
        ) : (
          <Link className="underline text-black" to="/login">
            Login Page
          </Link>
        )}
        .
      </span>
    )
  } else if (inviteInfo.currentAgency) {
    message = (
      <span>
        By accepting this invite, you will leave{' '}
        <b>{inviteInfo.currentAgency}</b> and instead join{' '}
        <b>{inviteInfo.agency}</b>.
      </span>
    )
  } else {
    message = (
      <span>Join {inviteInfo.agency} by clicking the button below.</span>
    )
  }

  return (
    <div>
      <div className="w-full flex flex-col max-w-md m-auto">
        <div className="flex justify-center pt-12">
          <a href="https://www.practicallymagic.co/" className="p-4">
            <img src="/assets/logo.png" alt="Practically Magic logo" />
          </a>
        </div>

        <div className="flex flex-col justify-center my-auto pt-8 px-8">
          <p className="text-center text-3xl">
            Join {inviteInfo.agency || 'Agency'}
          </p>

          {message && <div className="bg-black-100 mt-12 p-3">{message}</div>}

          {canJoin && inviteInfo.existingUser && (
            <AcceptInviteExistingUser
              agency={inviteInfo.agency}
              token={token}
            />
          )}

          {canJoin && !inviteInfo.existingUser && (
            <AcceptInviteNewUser
              agency={inviteInfo.agency}
              token={token}
              email={inviteInfo.email}
            />
          )}
        </div>
      </div>
    </div>
  )
}
