import { useEffect, useState } from "react";
import { differenceInDays } from "date-fns";
import { Drawer } from "@material-ui/core";

function showiOSPrompt() {
  if ((navigator as any).standalone) {
    return false;
  }
  const today = new Date()
  const lps = localStorage.getItem("installPrompt")
  const lastPrompt = lps ? Date.parse(lps) : null;
  const days = lastPrompt ? differenceInDays(today, lastPrompt) : null;
  const ua = window.navigator.userAgent;
  const webkit = !!ua.match(/WebKit/i);
  const isIPad = !!ua.match(/iPad/i);
  const isIPhone = !!ua.match(/iPhone/i)
  const isIOS = isIPad || isIPhone;
  const isSafari = isIOS && webkit && !ua.match(/CriOS/i);

  const prompt = (days === null || days > 30) && isIOS && isSafari;

  if (prompt && "localStorage" in window) {
    localStorage.setItem("installPrompt", today.toISOString());
  }

  if (process.env.NODE_ENV === "development") {
    console.log({ isIOS, isSafari, prompt })
  }

  return prompt
}

export const AddToHome = ({ ...props }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  useEffect(() => { setModalOpen(showiOSPrompt()) }, [])

  const shareIcon = <svg xmlns="http://www.w3.org/2000/svg" className="inline-block -mt-2" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 5l-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z"/></svg>

  return (
        <Drawer
          anchor="bottom"
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onClick={() => setModalOpen(false)}
          PaperProps={{
            className: "text-center p-3 flex space-y-2"
          }}
        >
          <p>Install this website to your home screen for easy access!</p>
          <p>Tap {shareIcon}, then "Add to Home Screen".</p>
          <span className="text-xs">(Tap anywhere to dismiss)</span>
        </Drawer>
  )
}

export default AddToHome
