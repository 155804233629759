import {
  createContext,
  FC,
  useContext,
  useState,
  useRef,
  ReactNode,
} from 'react'
import { ConfirmationDialog, ConfirmationOptions } from './ConfirmationDialog'

const ConfirmationServiceContext = createContext<
  (options: ConfirmationOptions) => Promise<void>
>(Promise.reject)

export const useConfirmation = () => useContext(ConfirmationServiceContext)

export const ConfirmationServiceProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [confirmationState, setConfirmationState] = useState<
    ConfirmationOptions
  >({ title: '', description: '', confirmButton: 'delete' })

  const [isOpen, setIsOpen] = useState(false)

  const awaitingPromiseRef = useRef<{
    resolve: () => void
    reject: () => void
  }>()

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options)
    setIsOpen(true)
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject }
    })
  }

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject()
    }

    setIsOpen(false)
  }

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve()
    }

    setIsOpen(false)
  }

  return (
    <>
      <ConfirmationServiceContext.Provider
        value={openConfirmation}
        children={children}
      />

      <ConfirmationDialog
        open={isOpen}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...confirmationState}
      />
    </>
  )
}
