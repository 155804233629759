import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Switch,
  Tooltip,
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import React, { FC, useState } from 'react'
import { FieldValues, FormState } from 'react-hook-form'
import { get } from 'lodash'

import {
  camelToCapitalWords,
  emailRegex,
  last,
  singularize,
  withOrdinal,
} from '../../../helpers/utils'
import { FormFieldDescription, FormFieldTypes } from '../FormBuilder'
import { BasicTextField } from './BasicTextField'
import { DateField } from './DateField'
import { ImageField } from './ImageField'
import { TimeInputField } from './TimeInputField'
import { ColorField } from './ColorField'
import { SelectField } from './SelectField'
import { RichTextField } from './RichTextField'

const mergeFieldProps = (formProps: any, fieldProps: any) => {
  // Check if either is blank and return the other.
  if (!formProps) {
    return fieldProps || {}
  } else if (!fieldProps) {
    return formProps || {}
  }

  // Get the combined keys of both.
  const keys = new Set(Object.keys(formProps).concat(Object.keys(fieldProps)))

  // Merge the properties for each field type.
  const props = {} as any
  keys.forEach((key) => {
    props[key] = Object.assign({}, formProps[key], fieldProps[key])
  })
  return props
}

export const FormBuilderField: FC<{
  formState: FormState<FieldValues>
  control: any
  setError: any
  handleToggle: (name: string, namePrefix?: string) => void
  clearErrors: any
  errors: any
  register: any
  getValues: any
  field: FormFieldDescription
  namePrefix?: string
  item: any
  fieldProps: any
  needsReconfirm?: boolean
  disabled?: boolean
}> = ({
  formState,
  control,
  setError,
  handleToggle,
  clearErrors,
  errors,
  register,
  getValues,
  field,
  namePrefix,
  item,
  fieldProps,
  needsReconfirm,
  disabled: disabledProp,
}) => {
  //** the item that is showing in the intinerary page has a contentId prop, so we are using this prop to check if the value is a item or a content*/
  const isItemOrContent = item?.contentId ? item?.notes : item?.template?.notes
  const [value, setItemValue] = useState(
    field.name === 'template.notes' ? isItemOrContent : get(item, field.name)
  )

  const disabled =
    disabledProp ||
    (field.disabledToggle && getValues(field.disabledToggle) === false) || // Explicitly ignore when undefined
    false

  const requiredToggle = field.requiredToggle
    ? getValues(field.requiredToggle)
    : false

  // Use provided label OR compute label from name.
  // We convert camel to words, capitalize all words and remove
  // any trailing "At", e.g. "checkInAt" becomes "Check In"
  const name = last(field.name.split('.'))
  const label =
    field.label !== undefined
      ? field.label
      : camelToCapitalWords(name).replace(/ At$/, '')

  const prefixedName = namePrefix ? `${namePrefix}.${field.name}` : field.name

  const mergedFieldProps = mergeFieldProps(fieldProps, field.fieldProps)

  const mainProps = {
    errors,
    register,
    name: prefixedName,
    label,
    disabled,
    value,
    required: (field.required || requiredToggle) && !disabled,
  } as any

  if (field.helperText) {
    mainProps.helperText = field.helperText
  }

  const handleRemoveArrayEntry = (index: number) => {
    let newValue = value.slice()
    newValue[index]._destroy = true
    setItemValue(newValue)

    // Mark the actual item's value with destroy so that we know to
    // include it when we save.
    // NOTE: We clean this up on cancel in FormBuilder:onCancelWrapper.
    const itemValue = get(item, field.name)
    if (itemValue && itemValue[index]) {
      itemValue[index]._destroy = true
    }
  }

  const handleAddArrayEntry = () => {
    setItemValue([...(value || []), {}])
  }

  const error = get(errors, prefixedName)

  switch (field.type) {
    case FormFieldTypes.SELECT:
      return <SelectField {...mainProps} options={field.options} />
    case FormFieldTypes.COLOR:
      return <ColorField {...mainProps} />
    case FormFieldTypes.HIDDEN:
      return (
        <input
          type="hidden"
          ref={register}
          name={mainProps.name}
          value={mainProps.value || ''}
        />
      )
    case FormFieldTypes.GROUP:
      return (
        <FormControl component="fieldset" className="space-y-5 w-full">
          <FormLabel
            component="legend"
            className="text-lg text-black-800 font-medium"
          >
            {label}
          </FormLabel>

          <div className="space-y-5">
            {field.fields?.map((subField) => {
              return (
                <FormBuilderField
                  key={subField.name}
                  handleToggle={handleToggle}
                  formState={formState}
                  control={control}
                  setError={setError}
                  clearErrors={clearErrors}
                  errors={errors}
                  register={register}
                  getValues={getValues}
                  field={subField}
                  namePrefix={field.namePrefix}
                  item={item}
                  fieldProps={mergedFieldProps}
                  needsReconfirm={needsReconfirm}
                  disabled={disabledProp}
                />
              )
            })}
          </div>
        </FormControl>
      )

    case FormFieldTypes.ARRAY: {
      // We need to manually manage whether or not our array is required
      // and yet has 0 entries.
      const count = (value || []).filter((v: any) => !v._destroy).length
      const isArrayError = error?.type === 'array'
      const showArrayError = isArrayError && formState.isSubmitted
      if (!error && count === 0 && mainProps.required) {
        // Timeout works around render causing a re-render.
        setTimeout(
          () =>
            setError(prefixedName, {
              type: 'array',
              message: `Must have at least one ${singularize(label)}.`,
            }),
          0
        )
      } else if (isArrayError && count > 0) {
        // Timeout works around render causing a re-render.
        setTimeout(() => clearErrors(prefixedName), 0)
      }

      let skipped = 0

      return (
        <div className="space-y-10">
          {(value || []).map((entry: any, index: number) => {
            if (entry._destroy) {
              skipped += 1
              return null
            }

            return (
              <FormControl
                key={index}
                component="fieldset"
                className="space-y-5 w-full"
              >
                <div className="flex justify-between -mb-2">
                  <FormLabel
                    component="legend"
                    className="text-lg text-black-800 font-medium"
                  >
                    {/* E.g. "1st Connection" */}
                    {withOrdinal(index + 1 - skipped)} {singularize(label)}
                  </FormLabel>

                  <Tooltip
                    arrow
                    title={`Remove ${singularize(label)}`}
                    classes={{ popper: '-mt-2' }}
                    placement="bottom"
                  >
                    <Button
                      type="button"
                      onClick={() => handleRemoveArrayEntry(index)}
                    >
                      <Delete fontSize="small" color="secondary" />
                    </Button>
                  </Tooltip>
                </div>

                <div className="space-y-5">
                  {field.fields?.map((subField) => {
                    const adjustedField = { ...subField }
                    adjustedField.name = `${field.name}[${index}].${subField.name}`

                    return (
                      <FormBuilderField
                        key={adjustedField.name}
                        handleToggle={handleToggle}
                        formState={formState}
                        control={control}
                        setError={setError}
                        clearErrors={clearErrors}
                        errors={errors}
                        register={register}
                        getValues={getValues}
                        field={adjustedField}
                        namePrefix={field.namePrefix}
                        item={item}
                        fieldProps={mergedFieldProps}
                        needsReconfirm={needsReconfirm}
                        disabled={disabledProp}
                      />
                    )
                  })}
                </div>
              </FormControl>
            )
          })}
          <div className="flex items-center space-x-2">
            <Button
              type="button"
              variant="outlined"
              onClick={handleAddArrayEntry}
              color={showArrayError ? 'secondary' : 'primary'}
            >
              Add {singularize(label)}
            </Button>
            {showArrayError && (
              <span className="text-red-500">{error.message}</span>
            )}
          </div>
        </div>
      )
    }

    case FormFieldTypes.DATE_TIME:
      return (
        <FormControl component="fieldset" className="w-full">
          <div className="flex space-x-3">
            <DateField
              {...mainProps}
              needsReconfirm={needsReconfirm}
              datePickerProps={mergedFieldProps[FormFieldTypes.DATE]}
              name={`${prefixedName}.date`}
              className="w-3/5"
              label={`${label} Date`}
              control={control}
              setError={setError}
              clearErrors={clearErrors}
            />
            <TimeInputField
              {...mainProps}
              {...mergedFieldProps[FormFieldTypes.TIME]}
              className="w-2/5"
              helperText="Start time (e.g. 10:30 AM)"
              name={`${prefixedName}.time`}
              label={`${label} Time`}
            />
          </div>
        </FormControl>
      )

    case FormFieldTypes.ROW:
      return (
        <FormControl component="fieldset" className="w-full">
          <div className="flex space-x-3">
            {field.fields?.map((subField) => {
              return (
                <FormBuilderField
                  key={subField.name}
                  handleToggle={handleToggle}
                  formState={formState}
                  control={control}
                  setError={setError}
                  clearErrors={clearErrors}
                  errors={errors}
                  register={register}
                  getValues={getValues}
                  field={subField}
                  namePrefix={field.namePrefix}
                  item={item}
                  fieldProps={mergedFieldProps}
                  needsReconfirm={needsReconfirm}
                  disabled={disabledProp}
                />
              )
            })}
          </div>
        </FormControl>
      )

    case FormFieldTypes.DATE:
      return (
        <DateField
          {...mainProps}
          datePickerProps={mergedFieldProps[FormFieldTypes.DATE]}
          control={control}
          setError={setError}
          clearErrors={clearErrors}
        />
      )
    case FormFieldTypes.IMAGE:
      return <ImageField {...mainProps} />

    case FormFieldTypes.SWITCH:
      let exclusive: HTMLInputElement | null = null
      if (field.exclusiveWith) {
        const exSelector = `input[name='${field.exclusiveWith}']`
        exclusive = document.querySelector(exSelector)
      }

      return (
        <div>
          <FormControl component="fieldset">
            <FormControlLabel
              control={
                <Switch
                  disabled={disabled}
                  defaultChecked={mainProps.value}
                  name={prefixedName}
                  inputRef={register}
                  onChange={(e) => {
                    if (exclusive?.checked && e.target.checked) { exclusive.click() }
                    handleToggle(prefixedName, namePrefix)
                  }}
                />
              }
              color="primary"
              label={label}
            />
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </FormControl>
        </div>
      )

    case FormFieldTypes.CHECKBOX:
      return (
        <FormControl component="fieldset">
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={mainProps.value}
                name={prefixedName}
                inputRef={register}
              />
            }
            color="primary"
            label={label}
          />
          {mainProps.helperText && (
            <FormHelperText>{mainProps.helperText}</FormHelperText>
          )}
        </FormControl>
      )

    case FormFieldTypes.LIST:
      return (
        <BasicTextField
          {...mainProps}
          className="w-full"
          multiline
          value={mainProps.value?.join(', ')}
          helperText="Enter comma separated list."
          rows={2}
        />
      )

    case FormFieldTypes.EMAIL:
      return (
        <BasicTextField
          {...mainProps}
          validationProps={{
            pattern: {
              value: emailRegex,
              message: 'Invalid Email Address.',
            },
          }}
          className="w-full"
        />
      )
    case FormFieldTypes.STRING:
      return <BasicTextField {...mainProps} className="w-full" />

    case FormFieldTypes.PASSWORD:
      return (
        <BasicTextField type="password" {...mainProps} className="w-full" />
      )

    case FormFieldTypes.TEXTAREA:
      return (
        <RichTextField rows={3} className="w-full" {...mainProps} />
      )

    case FormFieldTypes.TIME:
      return (
        <TimeInputField
          className="w-full"
          helperText="Start time (e.g. 10:30 AM)"
          {...mainProps}
        />
      )

    default:
      return null
  }
}
