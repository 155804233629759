import ErrorIcon from '@material-ui/icons/Error'
import cx from 'classnames'
import React, { FC } from 'react'

import { pdfTitleClasses } from '../../helpers/pdf'
import {
  Activity,
  Air,
  Attraction,
  Cruise,
  Ground,
  ItineraryItem,
  Lodging,
  Notes,
  Restaurant,
  Ticket,
} from '../../types/items'

import { PDFActivityItem } from '../pdf/items/PDFActivityItem'
import { PDFAirItem } from '../pdf/items/PDFAirItem'
import { PDFAttractionItem } from '../pdf/items/PDFAttractionItem'
import { PDFCruiseItem } from '../pdf/items/PDFCruiseItem'
import { PDFGroundItem } from '../pdf/items/PDFGroundItem'
import { PDFLodgingItem } from '../pdf/items/PDFLodgingItem'
import { PDFNotesItem } from '../pdf/items/PDFNotesItem'
import { PDFRestaurantItem } from '../pdf/items/PDFRestaurantItem'
import { PDFTicketItem } from '../pdf/items/PDFTicketItem'
import { Interweave } from 'interweave'
import { UrlMatcher } from 'interweave-autolink'

export const PublicItem: FC<{
  item: ItineraryItem
  date: Date
  priColor: string
  secColor: string
}> = ({ item, date, priColor, secColor }) => {
  const content = () => {
    switch (item.constructor) {
      case Activity:
        return {
          icon: (item as Activity).passEnabled
            ? 'fa-arrow-circle-right'
            : 'fa-star',
          item: <PDFActivityItem date={date} item={item as Activity} />,
        }
      case Attraction:
        return {
          icon: (item as Attraction).passEnabled
            ? 'fa-arrow-circle-right'
            : null,
          item: <PDFAttractionItem date={date} item={item as Attraction} />,
        }
      case Restaurant:
        return {
          icon: 'fa-cutlery',
          item: <PDFRestaurantItem date={date} item={item as Restaurant} />,
        }
      case Lodging:
        return {
          icon: 'fa-hotel',
          item: <PDFLodgingItem date={date} item={item as Lodging} />,
        }
      case Ticket:
        return {
          icon: 'fa-ticket',
          item: <PDFTicketItem date={date} item={item as Ticket} />,
        }
      case Ground:
        return {
          icon: 'fa-taxi',
          item: <PDFGroundItem date={date} item={item as Ground} />,
        }
      case Cruise:
        return {
          //** we are validating that cruise item has required field  */
          icon: (item as Cruise).departureAt ? 'fa-ship' : null,
          item: (item as Cruise).departureAt ? (
            <PDFCruiseItem date={date} item={item as Cruise} />
          ) : null,
        }
      case Air:
        return {
          icon: 'fa-plane',
          item: <PDFAirItem date={date} item={item as Air} />,
        }
      case Notes:
        return {
          icon: 'fa-sticky-note',
          item: <PDFNotesItem date={date} item={item as Notes} />,
        }
      default:
        return { icon: ErrorIcon, item: <div>something went wrong</div> }
    }
  }

  const { icon, item: itemNode } = content()
  const notes = item.isSecondDate(date) ? item.notes2 : item.notes
  const faIcon = icon && (<i className={cx('fa', icon)} style={{color: secColor}} />)
  const titleIcon = faIcon && <div className="inline-block mr-2 sm:hidden">{faIcon}</div>

  const itemTitle = () => {
    if (item.link) {
      return <a style={{ color: priColor }} className={pdfTitleClasses} href={item.link}>
        {titleIcon}{item.pdfItemTitle(date)}
      </a>
    } else {
      return <div style={{ color: priColor }} className={pdfTitleClasses}>
        {titleIcon}{item.pdfItemTitle(date)}
      </div>
    }
  }

  return item ? (
    <div className="flex sm:space-x-3 break-inside-avoid">
      <div className="leading-none text-3xl w-9 flex-shrink-0 text-right hidden sm:block">{faIcon}</div>
      <div className="flex-grow space-y-2 sm:space-y-4">
        {itemTitle()}
        {itemNode}
        {notes && (
          <div>
            <div className="font-semibold italic">Notes:</div>
            <div className="font-light leading-tight whitespace-pre-wrap prose">
              <Interweave content={notes} matchers={[new UrlMatcher('url')]} />
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div />
  )
}
