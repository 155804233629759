import { FC } from 'react'
import { displayTime } from '../../../helpers/utils'
import { Air } from '../../../types/items/'
import { PDFItemDetail, PDFItemDetails } from './helpers/PDFItemDetail'

export const PDFAirItem: FC<{ item: Air; date: Date }> = ({ item, date }) => {
  return (
    <div className="space-y-3">
      <PDFItemDetails>
        <PDFItemDetail field="Airline" value={item.airline} />
        <PDFItemDetail field="Confirmation" value={item.confirmation!} />
      </PDFItemDetails>
      {item.connections.map((connection) => (
        <div key={connection.id}>
          <PDFItemDetails>
            <PDFItemDetail field="Flight" value={connection.flightNumber} />
          </PDFItemDetails>
          <PDFItemDetails>
            <PDFItemDetail
              field="Departs"
              value={displayTime(connection.departureAt)}
            />
            <PDFItemDetail field="From" value={connection.departureCity} />
          </PDFItemDetails>
          <PDFItemDetails>
            <PDFItemDetail
              field="Arrives"
              value={displayTime(connection.arrivalAt)}
            />
            <PDFItemDetail field="In" value={connection.arrivalCity} />
          </PDFItemDetails>
        </div>
      ))}
    </div>
  )
}
