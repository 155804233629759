import { FC } from 'react'
import { displayTime } from '../../../helpers/utils'
import { Cruise } from '../../../types/items/'
import { PDFItemDetail, PDFItemDetails } from './helpers/PDFItemDetail'

export const PDFCruiseItem: FC<{ item: Cruise; date: Date }> = ({
  item,
  date,
}) => {
  const showDeparture = !item.isSecondDate(date)
  const departOrArrive = showDeparture ? item.startName : item.endName
  const departOrArriveTime = showDeparture ? item.departureAt : item.arrivalAt
  const port = showDeparture ? item.departurePort : item.arrivalPort

  return (
    <>
      <PDFItemDetails>
        <PDFItemDetail
          field={departOrArrive}
          value={displayTime(departOrArriveTime)}
        />
        <PDFItemDetail field="Port" value={port} />
        {showDeparture && (
          <PDFItemDetail field="Confirmation" value={item.confirmation!} />
        )}
      </PDFItemDetails>
      {showDeparture && (
        <PDFItemDetails>
          <PDFItemDetail field="Room Type" value={item.roomType} />
          <PDFItemDetail field="Room Number" value={item.roomNumber} />
        </PDFItemDetails>
      )}
    </>
  )
}
