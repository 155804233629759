import { FormHelperText } from '@material-ui/core'
import axios from 'axios'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { useSnackBar } from '../../../components/ui/SnackBarService'
import { getApiUrl, setUserData } from '../../../helpers/utils'
import { Agent } from '../../../types/agent'

interface AcceptInviteExistingUserProps {
  agency: string
  token: string
}

export const AcceptInviteExistingUser: FC<AcceptInviteExistingUserProps> = ({
  agency,
  token,
}) => {
  const [error, setError] = useState('')
  const { register, handleSubmit } = useForm()
  let history = useHistory()
  const snackBar = useSnackBar()

  const onSubmit = async (data: any) => {
    try {
      const result = await axios.post(
        getApiUrl(Agent.apiPath, null, 'accept_invite'),
        data
      )

      setUserData(result.data)
      history.push('/trips')
      snackBar({ message: `Successfully joined ${agency}.` })
    } catch (foo) {
      snackBar({ message: 'Failed to accept invitation.' })
      setError(
        foo.response.data?.error ||
          'Something went wrong trying to accept invitation.'
      )
    }
  }

  return (
    <form className="flex flex-col pt-3" onSubmit={handleSubmit(onSubmit)}>
      <input ref={register()} type="hidden" name="token" value={token} />
      <input
        type="submit"
        value="Accept Invite"
        className="cursor-pointer bg-black-900 text-white font-bold text-lg hover:bg-gray-700 p-2 mt-8"
      />
      {error && (
        <FormHelperText error className="text-base mt-2">
          {error}
        </FormHelperText>
      )}
    </form>
  )
}
