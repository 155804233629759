import { format } from 'date-fns'
import { FC } from 'react'
import { TimePattern } from '../../../helpers/utils'
import { BasicTextField, BasicTextFieldProp } from './BasicTextField'

export const TimeInputField: FC<BasicTextFieldProp> = ({ value, ...other }) => {
  const defaultValue = other?.defaultValue || ''

  return (
    <BasicTextField
      {...other}
      validationProps={{
        pattern: {
          value: TimePattern,
          message: 'Must be a valid time (e.g. "10:30 am").',
        },
      }}
      value={value ? format(value, 'p') : defaultValue}
    />
  )
}
