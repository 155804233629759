export class Agency {
  constructor(
    readonly id: number,
    public name: string,
    public logo: string,
    readonly manageAgents: boolean,
    readonly subscriptionCount: number,
    public primaryColor: string,
    public secondaryColor: string,
  ) {}

  static build(data: Agency) {
    return new Agency(
      data.id,
      data.name,
      data.logo,
      data.manageAgents,
      data.subscriptionCount,
      data.primaryColor,
      data.secondaryColor,
    )
  }

  get fullId() {
    return this.id
  }

  static apiPath = 'agencies'
}
