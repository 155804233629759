import { capitalize } from '@material-ui/core'
import { useEffect } from 'react'
import { format, add, isDate, isValid, parseISO } from 'date-fns'
import { toDate } from 'date-fns-tz'

// DRY way to remove this silly warning.
// See https://github.com/facebook/create-react-app/issues/6880 for more.
export const useEffectOnce = (effect: any) => {
  useEffect(() => {
    effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function formatCurrency(amount: number) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount)
}

export function urlSafe(url: string) {
  return url.replace(/[ /]/g, '-')
}

export function camelToCapitalWords(camel: string) {
  return camel
    .replace(/([A-Z])/g, ' $1')
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ')
}

// Very simple singularize method that simply removes trailing s.
export function singularize(word: string) {
  return word.replace(/s$/, '')
}

export function setUserData(data: any) {
  setLocalStorage('loggedIn', true)
  setLocalStorage('user', data)
  window.dispatchEvent(new Event('update-user'))
}

export function setLocalStorage(key: string, obj: any) {
  localStorage.setItem(key, JSON.stringify(obj))
}

export function getLocalStorage(key: string) {
  return JSON.parse(localStorage.getItem(key) || 'null')
}

export function setFormErrors(errors: any[], setError: any) {
  let first = true
  for (const [key, value] of Object.entries(errors)) {
    setError(key, {
      type: 'manual',
      message: Array.isArray(value) ? value.join(' ') : value,
      shouldFocus: first,
    })
    first = false
  }
}

export function last(array: any[]) {
  return array[array.length - 1]
}

// From: https://stackoverflow.com/a/31615643/2299503
export function getOrdinal(n: number) {
  let s = ['th', 'st', 'nd', 'rd'],
    v = n % 100
  return s[(v - 20) % 10] || s[v] || s[0]
}
export function withOrdinal(n: number) {
  return n + getOrdinal(n)
}

// Returns time in standard format.
// E.g. 12:30 PM
export function displayTime(date?: Date) {
  return date ? format(date, 'p') : undefined
}

// Returns date in nice readable format with day of week.
export function displayDate(date: Date) {
  return format(date, 'P')
  // EEEE, MMMM do
}

export function getDayOfWeek(date: Date) {
  return format(date, 'EEEE')
}

export function getMonth(date: Date) {
  return format(date, 'MMMM')
}

// E.g. "Dec 12"
export function getMonthAndDay(date: Date) {
  return format(date, 'MMM d')
}

export function getDayOfMonth(date: Date) {
  return format(date, 'd')
}

export const TimePattern = /^((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))$/

// From: https://stackoverflow.com/a/2188651
export function setTime(date: Date, timeString: string) {
  if (timeString === '') return null

  const time = timeString.match(/(\d+)(:(\d\d))?\s*(p?)/i)
  if (time === null) return null

  let hours = parseInt(time[1], 10)
  if (hours === 12 && !time[4]) {
    hours = 0
  } else {
    hours += hours < 12 && time[4] ? 12 : 0
  }
  const d = new Date(date)
  d.setHours(hours)
  d.setMinutes(parseInt(time[3], 10) || 0)
  d.setSeconds(0, 0)
  return d
}

export const getApiUrl = (
  path: string,
  id: string | number | null = null,
  extra: string | number | null = null
) => {
  let url = `/api/${path}`
  if (id !== null) url += `/${id}`
  if (extra !== null) url += `/${extra}`
  return url
}

export enum OffsetDirection {
  FORWARD,
  BACKWARD,
}

function addOffset(date: Date, direction: OffsetDirection) {
  let offset = date.getTimezoneOffset()
  if (direction === OffsetDirection.BACKWARD) { offset *= -1 }
  const offsetDate = add(date, { minutes: offset })
  return offsetDate
}

// Adjust all dates, converting them from local (browser) timezone to UTC or vice versa.
// This way setting something to "2:00 pm" will always be stored as 2pm UTC
// AND it will always be displayed as 2pm to the user regardless of their timezone.
export function fixTimezone(data: any, direction: OffsetDirection) {
  if (typeof data !== 'object') return

  if (Array.isArray(data)) {
    data.forEach((d) => fixTimezone(d, direction))
    return
  }

  Object.entries(data).forEach(([key, value]) => {
    // Only mess with our date keys which either end in "At" or
    // are "fastPass" or "reservation".
    if (key.endsWith('At') || ['fastPass', 'reservation'].includes(key)) {
      if (isDate(value)) {
        const dateValue = value as Date
        data[key] = addOffset(dateValue, direction)
      } else if (typeof value === 'string') {
        if (value.slice(-1) === "Z") {
          if (direction === OffsetDirection.FORWARD) {
            const dateValue = parseISO(value.slice(0, -1))
            if (isValid(dateValue)) { data[key] = dateValue }
          } else {
            const dateValue = parseISO(value)
            if (isValid(dateValue)) { data[key] = addOffset(dateValue, direction) }
          }
        } else if (value.length > 7) {
          // > 7 to prevent strings like "1112345" and "1112" from parsing as dates.
          const dateValue = new Date(value)
          if (isValid(dateValue)) {
            if (value.length < 11) {
              // Pure dates should not be offset, so we parse without time shift
              data[key] = toDate(value)
            } else {
              data[key] = addOffset(dateValue, direction)
            }
          }
        }
      }
    } else if (value) {
      fixTimezone(value, direction)
    }
  })
}
