import axios from 'axios'
import FileSaver from 'file-saver'
import { Trip } from '../types/trip'
import { getApiUrl } from './utils'
import { User } from 'types/user'

export const pdfGradient = 'bg-gradient-to-r from-pdf-primary to-pdf-secondary'
export const pdfHeadingClasses =
  'mx-6 py-1 text-5xl uppercase font-pdf-heading tracking-wider'
export const pdfTitleClasses =
  'text-2xl uppercase font-pdf-title tracking-wider text-pdf-primary'

const appHost = window.location.protocol + "//" + window.location.host
const pdfHost = process.env.REACT_APP_PDF_URL || 'https://pdf.practicallymagic.co'

export const downloadPdf = async (trip: Trip) => {
  const { data: { token }, } = await axios.get(getApiUrl(Trip.apiPath, trip.id, 'pdf_token'))
  const { data: user } = await axios.get(getApiUrl(User.apiPath))

  const footerStyles =
    'font-weight: 300;' +
    'color: #999;' +
    'font-family: Roboto, system-ui;' +
    'font-size: 5px;' +
    'width: 100%;' +
    'text-align: center'
  const footerCopy = [user.name, user.email, user.phoneNumber]
    .filter((copy) => copy) // not null or empty
    .join(' &nbsp;|&nbsp; ')
  const footer = `<p style='${footerStyles}'>${footerCopy}</p>`

  const header = '<p></p>' // Make header blank.

  const params = {
    url: `${appHost}/pdf/${trip.id}?token=${token}`,
    attachmentName: `${trip!.title}.pdf`,
    'pdf.displayHeaderFooter': 'true',
    'pdf.margin.top': '1cm',
    'pdf.margin.bottom': '1cm',
    'pdf.margin.left': '1cm',
    'pdf.margin.right': '1cm',
    'pdf.footerTemplate': footer,
    'pdf.headerTemplate': header,
    emulateScreenMedia: 'false',
  }
  const encodedParams = new URLSearchParams(params).toString()

  // First fetch the url and then save it. This way, we can await for
  // the download (fetch) to complete and then the save should be instant.
  const url = `${pdfHost}/api/render?${encodedParams}`
  return fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      return FileSaver.saveAs(blob, params.attachmentName)
    })
  }

