import { Button, Typography } from '@material-ui/core'
import axios from 'axios'
import { Image } from 'cloudinary-react'
import { useState } from 'react';
import { FormBuilder, FormFieldTypes } from '../../components/forms/FormBuilder'
import { Page } from '../../components/Page'
import { useConfirmation } from '../../components/ui/ConfirmationService'
import { SnackBarLink } from '../../components/ui/SnackBarLink'
import { useSnackBar } from '../../components/ui/SnackBarService'
import { useCrud } from '../../helpers/hooks/use_crud'
import {
  getApiUrl,
  setFormErrors,
  setUserData,
  useEffectOnce,
} from '../../helpers/utils'
import { Agency } from 'models/Agency'
import { User } from '../../types/user'

export const AgencyPage = () => {
  const userCrud = useCrud(User, false)
  const [user, setUser] = useState<User>()
  const snackBar = useSnackBar()
  const confirm = useConfirmation()

  useEffectOnce(() => {
    userCrud.get().then((user) => setUser(user))
  })

  if (!user) {
    return <Page paper className="space-y-8" loading={true} />
  }

  const agencyFields = [
    {
      name: 'manageAgents',
      label: 'Agency Owner / Manage Multiple Agents',
      type: FormFieldTypes.SWITCH,
      required: false,
    },
    {
      name: 'name',
      type: FormFieldTypes.STRING,
      requiredToggle: 'manageAgents',
    },
    { name: 'logo', type: FormFieldTypes.IMAGE, required: false },
    { name: 'primaryColor', label: "Primary color", type: FormFieldTypes.COLOR, required: false },
    { name: 'secondaryColor', label: "Secondary color", type: FormFieldTypes.COLOR, required: false },
  ]

  const onSubmitAgency = async (data: any, setError: any) => {
    const { data: result } = await axios.patch(
      getApiUrl(Agency.apiPath, user.personalAgency.fullId),
      data
    )

    if (result.errors) {
      setFormErrors(result.errors, setError)
    } else {
      if (!user.personalAgency.manageAgents && data.manageAgents) {
        snackBar({
          message: (
            <span>
              You can now add agents by clicking on{' '}
              <SnackBarLink to="/agents">Agents</SnackBarLink> in the header.
            </span>
          ),
        })
      } else {
        snackBar({ message: 'Agency updated.' })
      }

      setUserData(result)
    }
  }

  const handleLeaveAgency = async () => {
    if (!user.agency) return

    try {
      await confirm({
        title: `Are you sure you want to leave "${user.agency.name}"?`,
        description:
          "You will no longer have access to any of the agency's specific content. " +
          'Any of your custom content and all of your trips will be left unchanged.',
        confirmButton: 'Leave',
      })

      await axios.delete(getApiUrl(Agency.apiPath, user.agency.fullId, 'leave'))
      snackBar({ message: 'Successfully left agency.' })
      user.agency = null
      setUser(new User(user)) // new User() is needed here to trigger the re-render.
    } catch {}
  }

  return (
    <Page paper className="space-y-8">
      <Typography className="capitalize" variant="h1">
        Agency Details
      </Typography>
      <div className="w-1/2 mx-auto space-y-8">
        <div className="space-y-8">
          <Typography className="capitalize" variant="h2">
            Agency
          </Typography>

          {user.agency && (
            <div className="space-y-3">
              <div className="space-x-2">
                <label>Name:</label>
                <span>{user.agency.name}</span>
              </div>
              <div className="space-y-1">
                <label>Logo:</label>
                <Image publicId={user.agency.logo} className="max-h-36" />
              </div>
              <Button
                color="secondary"
                onClick={handleLeaveAgency}
                variant="outlined"
                className="block ml-auto"
              >
                Leave Agency
              </Button>
            </div>
          )}

          {!user.agency && (
            <FormBuilder
              onSubmit={onSubmitAgency}
              saveText="Save Changes"
              item={user.personalAgency}
              formDesc={agencyFields}
            />
          )}
        </div>
      </div>
    </Page>
  )
}
