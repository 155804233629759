import { createMuiTheme } from '@material-ui/core'

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#282D55', // p_blue2
      dark: '#009E9E', // p_teal
    },
    secondary: {
      main: '#ED635B', // p_red
      light: '#D59AAB', // p_pink
    },
  },
  typography: {
    h1: {
      fontSize: 48,
    },
    h2: {
      fontSize: 24,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          '&:focus': { outline: 'none' },
        },
        outlined: {
          '&:focus': { outline: 'none' },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          fontSize: '1.13rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&$shrink': {
            transform: 'translate(14px, -7px) scale(0.85)',
          },
        },
      },
    },
  },
})
