import { Trip } from '../trip'
import { ItineraryItem, ItemType } from './base'
import { Activity } from './activity'
import { Air } from './air'
import { Attraction } from './attraction'
import { Cruise } from './cruise'
import { FullDay } from './full_day'
import { Ground } from './ground'
import { Lodging } from './lodging'
import { Notes } from './notes'
import { Restaurant } from './restaurant'
import { Ticket } from './ticket'

const itemClassByType = new Map<ItemType, typeof ItineraryItem>()
itemClassByType.set(ItemType.ACTIVITY, Activity)
itemClassByType.set(ItemType.AIR, Air)
itemClassByType.set(ItemType.ATTRACTION, Attraction)
itemClassByType.set(ItemType.CRUISE, Cruise)
itemClassByType.set(ItemType.RESTAURANT, Restaurant)
itemClassByType.set(ItemType.GROUND, Ground)
itemClassByType.set(ItemType.LODGING, Lodging)
itemClassByType.set(ItemType.TICKET, Ticket)
itemClassByType.set(ItemType.NOTES, Notes)
itemClassByType.set(ItemType.FULL_DAY, FullDay)

export function getItemClassForType(type: ItemType): typeof ItineraryItem {
  const clazz = itemClassByType.get(type)
  if (!clazz) {
    throw new Error(`Unable to get item class for ItemType.${type}`)
  }
  return clazz
}

export function getItineraryOnlyFormFields(type: ItemType, trip: Trip) {
  const itemClass = getItemClassForType(type)
  if (itemClass) {
    return itemClass.itineraryOnlyFormFields(trip)
  }
  return []
}

export function getItineraryWithContentFormFields(type: ItemType) {
  const itemClass = getItemClassForType(type)
  if (itemClass) {
    return itemClass.itineraryWithContentFormFields
  }
  return []
}

export {
  Activity,
  Air,
  Attraction,
  Cruise,
  Restaurant,
  Ground,
  Lodging,
  Ticket,
  Notes,
  ItineraryItem,
  ItemType,
  FullDay,
}
