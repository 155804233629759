import {
  createContext,
  FC,
  useContext,
  useState,
  useRef,
  ReactNode,
} from 'react'
import { routerHistory } from '../../helpers/app_setup'
import { NotificationDialog, NotificationOptions } from './NotificationDialog'

const NotificationServiceContext = createContext<
  (options: NotificationOptions) => Promise<void>
>(Promise.reject)

export const useNotification = () => useContext(NotificationServiceContext)

export const NotificationServiceProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [NotificationState, setNotificationState] = useState<
    NotificationOptions
  >({ title: '', description: '' })

  const [isOpen, setIsOpen] = useState(false)

  const awaitingPromiseRef = useRef<{
    resolve: () => void
    reject: () => void
  }>()

  routerHistory.listen((location) => {
    setIsOpen(false)
  })

  const openNotification = (options: NotificationOptions) => {
    setNotificationState(options)
    setIsOpen(true)
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject }
    })
  }

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve()
    }

    setIsOpen(false)
  }

  return (
    <>
      <NotificationServiceContext.Provider
        value={openNotification}
        children={children}
      />

      <NotificationDialog
        open={isOpen}
        onClose={handleClose}
        {...NotificationState}
      />
    </>
  )
}
