import { FC } from 'react'
import axios from "axios"
import { getApiUrl, useEffectOnce } from '../../helpers/utils'
import { User } from '../../types/user'

export const BillingRedirect: FC = () => {
  useEffectOnce(async (data: any, setError: any, reset: any) => {
    axios.post(getApiUrl(User.apiPath, "billing")).then((result) => {
      window.location.replace(result.data.url)
    }).catch((error) => {
      let msg = encodeURIComponent("We weren't able to open the billing portal.")
      window.location.replace(`/billing?message=${msg}`)
    })
  })

  return (<div></div>)
}
