import { FC, useState } from "react"
import { BasicTextFieldProp } from "./BasicTextField"
import { FormControl, FormLabel, MenuItem, Select } from "@material-ui/core"

interface SelectFieldProps {
  options: { value: string; label: string, className?: string }[]
}

export const SelectField: FC<BasicTextFieldProp & SelectFieldProps> = ({
  errors,
  name,
  label,
  value,
  helperText,
  required,
  register,
  options,
}) => {
  const [currentValue, setCurrentValue] = useState(value)

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" className="mb-1">{label}</FormLabel>
      <Select onChange={(e) => setCurrentValue(e.target.value)} value={currentValue}>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            className={option.className}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <input value={currentValue} name={name} type="hidden" ref={register({ required })} />
    </FormControl>
  )
}
